// main: ../../index.less
@import (reference) "../../config.less";
@import (reference) "button";

@buttonGroupSelector: .buttonGroup;

@{buttonGroupSelector}
{
	display: inline-flex;

	#set.margin(0, 4);
	&:last-child { margin-right: 0; }
	&:first-child { margin-left: 0; }
	+ @{buttonGroupSelector} { margin-left: 0; }

	&--small {
		@{buttonSelector}
		{
			height: 1.75em;

			&.is\:active {
				background: @header[@button][@active][bg];
				color: @header[@button][@active][color];
			}

			&::before {
				color: @color-primary-400;
			}
		}
	}

	@{buttonSelector}
	{

		border-radius: 0;

		&:not(:first-child) {
			#set.margin( 0, 0, 0, 1 );
		}
		&:not(:first-child):not(:last-child) {
			border-radius: 0;
		}
		&:first-child {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		&:last-child {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}
