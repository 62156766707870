 // main: ../build.less
 @import (reference) '../config';
 
.view {
	&--page {
		display: flex;
		flex-flow: column;
		// align-items: center;
		// justify-content: space-between;
		background-color: @color-white;
		margin-top: 0;

		min-height: 100vh;

		background-image: url('/images/wave.jpg');
		background-size: cover;

		@media @mobile {
			background-image: none;
			// justify-content: center;
		}

		@media @tablet {
			// justify-content: center;
			#set.padding(bottom, 64);
		}
	}

	&--page &Main {
		width: 100%;
		padding: 0;
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		flex: 1;
	}

}

