
.orderMenu {
	// #set.padding(8, 4, 4, 4);
	.contextMenu__item.is\:active {
		// font-weight: bold;
		// &:not(&:hover) {
		// 	background: rgba(0, 0, 0, 0.1);
		// 	background: fadeout(@color-primary, 80%);
		// }
	}
	
	.contextMenu__item:not(.is\:active)::before {
		opacity: 0;
		// font-weight: bold;
	}
}
