// main: ../build.less
@import (reference) '../config';

.searchInput {

	min-width: 3em;
	background: none;
	border: none;
	padding: 0;
	color: @header[color];
	display: flex;
	// padding-left: 0.66rem;
	padding-left: 0;
	padding-right: 0;
	height: @input[height];
	display: flex;
	align-items: center;
	
	&.has\:value .input {
		padding-right: 1rem;
	}
	.icon {
		display: none;
	}
	.input {
		padding-left: 0;
		padding-right: 0;
		@media @mobile {
			padding-left: .em(8)[];
		}
	}
	&__placeholder {
		display: inline-flex;
		align-items: center;
		position: relative;
		// width: 100%;
		padding-left: .em(6)[];
		height: @input[height];
		font-size: @input[font-size];
		color: @color-text-faded;
		user-select: none;
		
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 100%;

	}
	&__clear {
		position: sticky;
		right: 0.25em;
		margin-left: auto;
	}
	&__clear::before {
		#set.text(12, 18);
		font-weight: bold;
		background-color: @color[primary-100];
		border-radius: 50%;
		width: 1.5rem;
		padding: 0.05em;
		color: @color[primary-800];
	}
	&:focus {
		outline: none;
	}
	&::before, &__icon {
		margin-left: 0.5em;
		@media @mobile {
			display: none;
		}
	}
	.spinner {
		#set.margin(right, 8);
	}
	@media @fixed {
		.icon {
			display: inline-flex;
		}
	}
	
	.searchTagList {
		padding-left: 0;
		padding-right: 0;
		&:first-of-type {
			padding-left: 0.66rem;
		}
		&__items {
			flex-flow: row nowrap;
		}
	}
}

.searchSubmit {
	height: 100%;
	border-radius: 0.75em;
	//border-radius: 0;
	margin-right: -.1px; // Fix for mobile somehow having a small white space between the button and the search box border

	border-top-left-radius: 0;
	border-bottom-left-radius: 0;

	#set.padding(left, 16);
	#set.padding(right, 10);

	&::before {
		#set.text(20, 24);
		// margin-right: 0.33em !important;
		margin-right: 0.1em !important;
	}

	&__label {
		display: none;
	}

	@media @fixed {
		#set.padding(left, 16);
		#set.padding(right, 16);
		&__label {
			display: flex;
		}
		
		&::before {
			margin-right: 0.33em !important;
		}
	}
}

.proBadge {
	// display: inline-block;
	font-weight: bold;
	text-transform: uppercase;
	background-color: @color[primary-400];
	color: @color[white];
	#set.text(8.5, 18);
	#set.padding(0, 4);
	// // #set.margin(right, 8);
	border-radius: 0.25em;
}

.searchModeMenuTrigger {
	width: 3.75rem;
	flex: 0 0 3.75rem;
	height: 100%;
	border-bottom-left-radius: @header[@searchbox][border-radius];
	border-top-left-radius: @header[@searchbox][border-radius];
	// margin-right: 0.66rem;
	background: @color[primary-400];
	color: @color[white];
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.5rem 0 0.8rem;

	&__proBadge {
		background-color: @color[white];
		color: @color[primary-400];
	}
	
	&__chevron {
		display: none;
	}
	
	@media @tablet {
		flex-basis: 5rem;
		width: 5rem;
		&__chevron {
			display: inline-flex;
		}	
	}
}

.searchModeMenu {
	max-width: 14rem;
	padding: 0;

	&__proBadge {
		display: inline-block;
	}

	.contextMenu__item {
		align-items: center;
		display: flex;
		justify-content: flex-start;
	}

	.contextMenu__item::before {
		top: 0;
		#set.margin(right, 12);
		#set.margin(left, 4);
	}

	.contextMenu__item &__proBadge {
		#set.margin(right, 8);
	}

	.contextMenu__item:hover &__proBadge {
		background-color: @color[primary-100];
		color: @color[primary-400];
	}
}

.searchTokens {
	flex-flow: row wrap;
	&__section {
		flex: 1 1 22.5rem;
		margin-top: 0.33em;
	}
	&__heading {
		#set.text(13, 16);
		font-weight: 500;
		margin-bottom: 0.33em;
		display: flex;
		align-items: center;
		color: #444;
	}
	.searchTagList {
		border: 1px solid @color[primary-300];
		border-radius: @ui-rounding-input;
	}
}

// used for both suggestions and pro search
.searchPopover {
	position: absolute;
	top: 2.2rem;
	// left: -4.5rem;
	// right: -4.4rem;
	left: -3.5rem;
	right: -0.89rem;
	margin-top: 2rem;
	padding-bottom: 0.55em;
	background-color: white;
	display: none;
	// border-top-color: transparent;
	border-left: @header[@searchbox][border-width] solid @header[@searchbox][border];
	border-right: @header[@searchbox][border-width] solid @header[@searchbox][border];
	border-bottom: @header[@searchbox][border-width] solid @header[@searchbox][border];
	border-bottom-left-radius: @header[@searchbox][border-radius];
	border-bottom-right-radius: @header[@searchbox][border-radius];
	// width: 100%;

	div:first-of-type {
		padding-left: 1em;
		padding-right: 1em;
	}
	div:last-of-type {
		padding-right: 1em;
		padding-left: 1em;
	}

	&::before {
		content: '';
		display: block;
		width: 100%;
		// height: @header[@searchbox][border-width];
		// border-top: 1px solid @header[@searchbox][border];
		border-top: @header[@searchbox][border-width] solid @color[primary-100];
		// border-color: @color[primary-300];
		margin-bottom: 0.55em;
		padding-bottom: 0.25em;
	}
	
	@media @tablet {
		top: 1em;
		left: 2.2em;
		right: 2.65em;
		margin-left: 2rem;
		&::before {
			margin: 0 auto;
			width: calc(100% - 1.75em);
			border-color: @header[@searchbox][border];
			// border-top: 1px solid @header[@searchbox][border];
		}
	}
	@media @fixed {
		width: auto;
		right: 8.8rem;
		&::before {
			margin: 0 auto;
			// width: calc(100% - 4em);
			width: calc(100% - 1.75em);
		}
	}
}

.searchSuggestions {
	&__took {
		#set.text(12, 16);
		font-weight: bold;
		color: @color-text-muted;
		position: absolute;
		right: 2rem;
		top: 1rem;
	}

	&__item {
		// padding: 0.2em 3rem;
		// padding: 0.2em 1rem;
		padding: 0.2em 1.5rem;
		#set.text(18, 24);
		b {
			font-weight: bold;
			color: @color-primary-400;
		}
		&.is\:highlighted {
			background-color: @color-primary-50;
		}
		&:hover {
			background-color: @color-primary-100;
			cursor: default;
		}
		@media @fixed {
			padding: 0.2em 2.5rem !important;
		}
	}
}


@searchTagList: {
	height: .em(22)[];
	spacing: .em(8)[];
	border-radius: @ui-rounding-controls;
	bg: @color[primary-100];
	color: @color-text;
	
	@prefix: {
		bg: @color[primary-400];
	}
	
	@hover: {
		bg: @color[primary-300];
		color: @color[white];
	}
	// @marker: {
	// 	bg: @color[primary-200];
	// 	color: @color[primary-500];
	// }
}

.searchTagList {
	display: flex;
	flex-flow: row wrap;
	padding: 0.25em 0.5em 0.25em 0.5em;
	
	&__items {
		// display: flex;
		display: none;
		flex-flow: row wrap;
		align-items: center;
		flex: 0 1 auto;
		margin-bottom: 0.25em;
	}

	&__item {
		display: inline-flex;
		flex-flow: row nowrap;
		// flex-flow: row nowrap;
		// align-items: center;
		margin-top: 0.25em;
		max-width: 100%;
		height: @searchTagList[height];
		// margin-top: @searchTagList[spacing];
		margin-right: @searchTagList[spacing];
		border-radius: @searchTagList[border-radius];


		transition: @transition[@controls][smooth];
		background-color: @searchTagList[bg];

		white-space: nowrap;
		// cursor: pointer;
		// &:hover &Label {
		// 	background-color: @searchTagList[@hover][bg];
		// }
		
		&Prefix {
			#set.text(12, 16);
			background-color: @searchTagList[@prefix][bg];
			padding: 0.25em 0.33em 0.25em 0.33em;
			font-weight: bold;
			height: 100%;
			color: @color[white];
			border-top-left-radius: @searchTagList[border-radius];
			border-bottom-left-radius: @searchTagList[border-radius];
			// display: flex;
			// align-items: center;
		}
		
		&Label {
			#set.text(12, 16);
			height: 100%;
			padding: 0.25em 0.33em;
			background-color: @searchTagList[bg];
			border-top-left-radius: @searchTagList[border-radius];
			border-bottom-left-radius: @searchTagList[border-radius];
		}
		
		&Clear {
			background: @searchTagList[bg];
			font-weight: bold;
			border-top-right-radius: @searchTagList[border-radius];
			border-bottom-right-radius: @searchTagList[border-radius];
			width: 1.75rem;
			color: @color[primary-800];
			padding: 0.25em;
			transition: all 0.12s ease-in-out;
			&:hover {
				background-color: @searchTagList[@hover][bg];
				color: @searchTagList[@hover][color];
				cursor: pointer;
			}
			&::before {
				#set.text(14, 14);
				padding-right: 0.25em;
			}
		}
	}

	&__input {
		display: inline-flex;
		flex-flow: row nowrap;
		flex: 1 1 auto;
		padding-top: 0.15em;
		padding-bottom: 0.15em;
		&Text {
			display: inline-flex;
			flex: 1;
			width: 100%;
			border: none;
			border-radius: 0;
			padding: 0.2em;
			height: 1.5em;
			&:focus {
				outline: none;
			}
		}
		&Add {
			opacity: 1;
			transition: opacity 0.12s ease-in-out;
			border-radius: 50%;
			height: 2rem;
			width: 2rem !important;
			&::before {
				#set.text(14, 14);
			}
			
			&:focus {
			}
			&:focus &::before{
			}
		}
		&Add[disabled] {
			opacity: 0;
		}
	}
	
	
	&.has\:value &__items {
		display: flex;
	}
}
