// main: ../build.less
@import (reference) '../config';

@compactListSelector: ~".is\:compact";

.headlineList {
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;

	&__header {
		border-bottom: 1px solid @color-primary;

		@media @tablet {
			margin-top: 1.5em;
		}

		&Title {
			#set.text(24, 24);
			font-weight: bold;

			&.has\:icon::before {
				font-size: inherit;
				display:inline-block;
				#set.rem(width, 40);

				@media @tablet {
					#set.rem(width, 50);
				}
			}

			@media @tablet {
				#set.text(32, 32);
			}
			@media @desktop {
				#set.text(38, 38);
			}
		}

		&Icon {
			display: inline-block;
			#set.rem(width, 40);
			@media @tablet {
				#set.rem(width, 60);
			}
		}

		&Filters {
			display: flex;
			justify-content: space-between;
			flex-flow: row wrap;
		}

		&Meta {
			width: 100%;
			display: inline-flex;
			align-items: flex-start;
			#set.text(14, 22);
			padding: 0.5em 0 0.66em;
			flex-flow: row wrap;
			@media @tablet {
				#set.text(16, 22);
			}
		}
		&.has\:icon &Meta {
			#set.margin(left, 6); // dont align with HL on mobile, wasting space

			@media @tablet {
				#set.margin(left, 64);
			}
		}
	}
	&__header.has\:icon .filterTagList {
		@media @tablet {
			margin-left: .em(60, @tag[size--small])[];
			// flex-flow: row wrap;
			// overflow: visible;
		}
	}

	// &__header .customStreamBtn {
	// 	position: absolute;
	// 	bottom: 2rem;
	// 	// left: auto;
	// 	// right: auto;
	// 	z-index: 9999;
	// 	box-shadow: @menu[box-shadow];
	// 	// right: 2rem;
	// 	@media @tablet {
	// 		z-index: auto;
	// 		position: static;
	// 		box-shadow: none;
	// 	}
	// }



	// &FilterLabel {
	// 	font-size: .em(16)[];
	// 	color: @color-text;
	// 	font-weight: bold;
	// }

	// &FilterTags {
	// 	align-items: baseline;
	// 	// font-size: .em(@tag[size--normal])[];
	// 	font-size: .em(@tag[size--small])[];

	// 	padding: .em(12, @tag[size--small])[] 0 0em;
	// 	flex-shrink: 0;
	// 	color: white;
	// 	@filterTag-border-radius: 3;

	// 	gap: .em(8, @tag[size--small])[];

	// 	display: flex;
	// 	flex-flow: row nowrap;
	// 	overflow-x: auto;
	// 	overflow-y: visible;

	// 	@media @tablet {
	// 		// margin-left: .em(60, @tag[size--small])[];
	// 		flex-flow: row wrap;
	// 		overflow: visible;
	// 	}

	// }

	&__info {
		>*:not(:first-child) {
			padding-left: 0.75em;
			margin-left: 0.75em;
			border-left: 1px solid @color-black-500;
		}
	}

	&__controls {

		.buttonGroup, .button {
			display: none;
			visibility: hidden;

			@media @tablet {
				display: flex;
				visibility: visible;
				margin-left: auto;
			}
		}
		&> .buttonGroup, &>.button {
			#set.margin(left, 4);
			margin-right: 0;
		}

		display: flex;
		visibility: visible;
		margin-left: auto;

		.switch {
			margin-right: .25em;
		}
		.switch__labelText {
			display: none;
		}
		@media @tablet {
			.switch__labelText {
				display: inline;
			}
		}
		.button {
			@media @tablet {
				display: flex;
			}
			height: @buttons[@size][@small][height];
			width: @buttons[@size][@small][height];
			justify-content: center;
			align-items: center;
			border-radius: @ui-rounding-controls;
			background-color: fade(@color-primary-100, 30%);
			color: @color-primary-400;

			&::before {
				color: @color-primary-400;
			}
			&:hover {
				background-color: fade(@color-primary-100, 50%);
			}
		}
		.button.is\:active {
			color: @buttons[@primary][color];
			&::before { color: @buttons[@primary][color]; }
			background-color: @color-primary-300 !important;
		}
	}


	&__item {

		position: relative;
		#set.padding(16, 12, 16, 0);

		@media @tablet { #set.padding(21, 12, 21, 6); }
		@media @desktop { #set.padding(22, 12, 24, 6); }

		&Header {
			#set.text(10, 18);
			display: flex;
			align-items: flex-end;

			@media @tablet {
				align-items: center;
				#set.text(12, 20);
			}
		}

		&Reputation {
			display: flex;
			cursor: pointer;
			// #set.margin(left, 4);

			&.is\:trusted .icon::before {
				color: @color-primary;
			}
			&.is\:suspect .icon::before {
				// color: @color-black-600;
				color: @color-text-error;
			}
		}

		&Meta {
			display: inline-flex;
			align-items: center;

			color: @color-black-600;

			// #set.padding( top, 4 );

			>* {
				#set.padding(0, 8);

				&:first-child {
					padding-left: 8;
				}
				&:not(:first-child) {
					border-left: 1px solid @color-primary-200;
				}
			}

			// @media @tablet {
				// padding-top: 0;
				// >*:first-child { #set.padding( left, 8); }
			// }
		}

		&AIResultBadge {
			color: @color-primary-400;
			display: flex;
			align-items: center;
			border: 1px solid @color-primary-400;
			border-radius: .75em;
			padding: 0 .5rem;
			&:before {
				margin-right: .25em;
				font-size: 1.2rem;
			}
		}

		&Lang {
			background-color: transparent;
			color: inherit;
		}

		&Actions {
			position: absolute;
			#set.rem( top, 16 );
			right: 0;
			// #set.rem(width, 28);
			display: flex;
			flex-flow: row nowrap;
			background: transparent !important;
			color: @color-primary-500;
			gap: .3em;

			@media @tablet { #set.rem( right, 10 ); }
		}

		&Action {
			background: transparent !important;
			color: @color-primary-500;
			// #set.padding(0, 2);
			padding: 0;
		}

		// TODO should add context menu when more than 1 action
		// &Action + &Action {
		// 	#set.margin( left, 4 );
		// }

		// &FavButton {
		// 	position: absolute;
		// 	#set.rem( top, 14 );
		// 	right: 0;

		// 	background: transparent !important;
		// 	color: @color-primary-500;

		// 	@media @tablet { #set.rem( right, 10 ); }
		// }

		@media @desktop {
			&Action&DebugButton, &Action&ShareButton:not(.is\:open) {
				//display: none;
				//#set.margin(right, 4);
				// required to not make share menu that's connected to the button flicker
				 visibility: hidden;
			}
		}

		&DebugButton::before {
			#set.text(15, 15);
		}

		&Title {
			display: block;
			#set.text(16, 21);
			margin-top: 0.5em;
			font-weight: 600;
			text-decoration: none;

			&:hover,
			&:visited:hover {
				text-decoration: underline;
				color: @color-primary-500;
			}

			b {
				background-color: @color-primary-200;
			}

			@media @tablet {
				#set.text(17, 27);
			}
		}

		&Desc, &Longtext {
			#set.text(12, 18);
			margin-top: 0.5em;

			b {
				background-color: @color-primary-200;
			}
			span {
				#set.padding(right, 4);
			}
		}

		&Longtext {
			white-space: pre-line;
			border-top: 1px solid @color-primary-700;
			padding-top: 0.5em;
		}

		&SummaryBtn {
			padding: 0;
			transition: opacity 0.2s;
			color: @color-primary-500;
			display: inline;
			// #set.text(12, 15);
			line-height: 175%;

			&Label {
				display: inline-block;
				transition: all 0.2s;
				font-size: 1em;
				@media @desktop {
					transform: translateX(0.6em);
					opacity: 0;
				}
			}

			&Icon {
				display: inline-block;
				opacity: 1;
				padding: 0;
				font-size: 1em;
				margin-left: 0.1em;
				transform: translateY(0.1em);
				transition: all 0.2s;
				&::before {
					padding: 0;
					font-size: 1em;
				}
			}
		}

		@media @tablet {
			@{compactListSelector} & {
				#set.padding(12, 0, 12);
				display: grid;
				grid-template-rows: 1fr;
				// grid-template-areas: "title sentiment lang date bookmark source";
				// grid-template-columns: 4fr min-content min-content 0.5fr 0.25fr 1fr;
				// grid-template-areas: "lang title source date bookmark";
				// grid-template-columns: min-content 4fr 1fr 1.5em 1.5em;
				grid-template-areas: "title lang repuation source sentiment actions";
				grid-template-columns: 1fr auto auto min-content auto 2.5em;
				place-items: start end;
				grid-column-gap: 0.25em;

				// > *,
				// > * > *,
				// > * > * > * { outline: 1px solid red; }
			}
			// @{compactListSelector} &:has( &Sentiment ) {
			@{compactListSelector} &.show\:sentiment {
				grid-template-areas: "lang title source sentiment date bookmark";
				grid-template-columns: min-content 4fr 1fr 2em 1.5em 1.5em;
			}

			@{compactListSelector} &Desc, @{compactListSelector} &Longtext {
				display: none;
				visibility: hidden;
			}

			@{compactListSelector} &Sentiment {
				padding: 0;
				#set.padding(0, 4);
				grid-area: sentiment;
				place-self: start center;
				color: @color-primary-500;
			}

			@{compactListSelector} &Reputation {
				padding: 0;
				grid-area: repuation;
				place-self: start center;
				color: @color-primary-500;
			}

			@{compactListSelector} &Header,
			@{compactListSelector} &Meta {
				display: contents;

				>* {
					border: none !important;
				}
			}

			@{compactListSelector} &Actions {
				// padding-left: 0;
				position: static;
				grid-area: actions;
				width: 100%;
				justify-content: center;
			}

			@{compactListSelector}:hover &DebugButton {
				display: none !important;
			}
			@{compactListSelector} &ShareButton {
				display: flex;
			}

			@{compactListSelector} &Title {
				grid-area: title;
				place-self: center start;
				margin-top: 0;
				#set.text(14, 18);
				// font-weight: normal;
				font-weight: 500;
			}

			@{compactListSelector} &Published {
				padding: 0;
				grid-area: date;
				white-space: nowrap;
			}

			@{compactListSelector} &FavButton {
				padding-left: 0;
				position: static;
				grid-area: bookmark;
			}

			@{compactListSelector} &Lang {
				padding: 0;
				grid-area: lang;
				#set.padding(right, 4);
				// color: @color-primary-500;

				b {
					display: none;
				}

				display:flex;
				text-decoration: none;
			}

			@{compactListSelector} &Source {
				grid-area: source;
				place-self: start end;
				flex-flow: row-reverse nowrap;

				.sourceBadge {
					&__published {
						padding: 0 0.667em 0 0.45em;
						border-top-right-radius: @badge[border-radius];
						border-bottom-right-radius: @badge[border-radius];
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
					}
					&__host {
						padding: 0 0.667em 0 1em;
						border-top-left-radius: @badge[border-radius];
						border-bottom-left-radius: @badge[border-radius];
						border-top-right-radius: 0;
						border-bottom-right-radius: 0;
					}
				}
			}
		}

		&:hover &Action&DebugButton, &:hover &Action&ShareButton {
			//display: flex;
			visibility: visible;
		}

		&:hover &SummaryBtnLabel {
			opacity: 1;
			transform: translateX(0.1em);
		}
		&:hover &SummaryBtnIcon {
			transform-origin: center center;
			transform: translateY(0.1em) scale(1.15);
		}
	}

	// prevents the header title from being obscured by the custom stream button
	@media @tablet {
		&.has\:customStreams &__headerTitle {
			#set.margin(right, 200);
		}
	}

	// virtualized list
	.list {
		flex: 1;
	}

	.list__item+.list__item {
		border-top: 1px solid @color-primary-200;
	}

	// big spinner when list is loading
	&>.spinner {
		top: -4rem;
		position: relative;
		margin-top: auto;
		margin-bottom: auto;
	}

	// display loading indicator at end of list, if there is more data
	&.has\:more .list__footer {
		#set.rem(height, 128);
		display: flex;
	}
}

.overrideStreamTabs {
	display: flex;
	align-items: center;

	&__label {
		font-weight: bold;
		#set.text(20, 20);
		#set.margin(0, 10, 0, 4);
		display: none;
	}

	&__items {
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
	}

	&__item {
		flex: 0 1 auto;
		max-width: 50%;
		width: 100%;
		background-color: fade(@color-primary-100, 30%);
		color: @color-primary-400;
		margin-left: 0 !important;
	}

	&__itemPrefix {
		margin-right: 0.5em;
	}

	&__itemCount {
		position: absolute;
		top: -1em;
		font-size: 0.75em;
		padding: 0.1em 0.55em;
		border-radius: 1em;
		color: #fc5a0f;
		border: 1px solid #fc5a0f;
		background: white;
	}

	&__itemLabel {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	&__item::before {
		font-size: 1.15em;
	}

	&__item:first-of-type {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	&__item:last-of-type {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.button.is\:active {
		color: @buttons[@primary][color];
		&::before { color: @buttons[@primary][color]; }
		background-color: @color-primary-300 !important;
	}

	@media @tablet {
		&__items {
			width: auto;
		}
		&__item {
			flex: 0 1 0;
			max-width: 20rem;
		}
		&__label {
			display: block;
		}
	}

	// &__switch {
	// 	#set.margin(0, 0, 0, 4);
	// }
}

.noResults {
	margin: auto;
	padding-bottom: 4rem;
	text-align: center;

	&__title {
		.font--large;
		#set.margin(bottom, 8);
		font-weight: 600;
	}

	&__subtitle {
		.font--medium;
		#set.margin(top, 6);
	}

	&__actions {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		#set.margin(top, 34);
	}

	&__action {
		#set.margin(8, 8, 0, 8);
	}

}

