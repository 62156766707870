 // main: ../build.less
 @import (reference) '../config';

.bigText {
	width: 100%;
	height: 100%;
	// background-color: red;
	// #em.text( 28, 42 );
	font-size: .em(21)[];
	line-height: 1.333;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	strong {
		font-size: .em(28, 21)[];
		font-weight: 600;
		margin-bottom: .em(6, 28)[];
	}

	&::before {
		display: block;
		text-align: center;
		font-size: .em(64, 21)[];
		margin-bottom: .em(6, 21)[];
		// transform: translateY(-8rem);
	}

	.viewMain--splashScreen & {
		margin-top: .em(-48, 21)[];
	}
}
