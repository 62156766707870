// main: ../build.less
@import (reference) '../config';

.select {
	display: inline-block;
	position: relative;
	font-size: @input[font-size];
	border: @input[border-width] solid @input[border];
	height: @input[height];

	&,
	&__body,
	&__value {
		border-radius: @select[border-radius];
	}

	&__body {
		display: flex;
		align-items: center;
		height: @input[height] - (@input[border-width] *2);
		background-color: @input[bg];
		cursor: pointer;
	}

	&__value {
		width: 100%;
		// background-color: inherit;
		inset-inline-end: 4rem;
		line-height: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-flex;
		align-items: center;
		padding: @input[@padding][vertical] @input[@padding][horizontal];
		height: @input[height] - (@input[border-width] *2);
		pointer-events: none;
	}

	&__search::placeholder,
	&__placeholder {
		opacity: 1;
		color: @input[placeholder];
	}

	// naked variant
	&--naked { border: none; }
	&--naked &__body,
	&--naked &__value { justify-content: end; }
	&--naked &__value {
		padding-right: (@input[@padding][horizontal] / 3);
		color: @select[@control][color]; // make it visually "belong" to toggle
		text-align: right;
	}

	&__search {
		background: transparent !important;
		border: none;
		color: inherit;
		width: 100%;
		height: auto;
		max-height: 100%;
		padding-top: 0;
		padding-bottom: 0;
		padding-right: 0;

		&:focus {
			outline: none;
		}
	}

	&__toggle,
	&__clear {
		background: transparent !important;
		border: none !important;
		height: 100%;
		width: .em(32)[];
		color: @select[@control][color];

		&:hover {
			color: @select[@control][@hover][color];
		}
	}

	&__body:focus,
	&__toggle:focus,
	&__clear:focus {
		outline: none;
	}

	&:focus-visible,
	&:focus,
	&:has(:focus-visible),
	&:has(:focus)
	{
		border-color: @color-primary;
	}

	// &__clear {
	// 	background-color: red !important;
	// }
}

.select--checkList {

	.select__value {
		pointer-events: none;
		position: absolute;
		left: 0;
		width: auto;

	}
	&.has\:value .select__search {
		opacity: 0;
	}
	&:focus-within .select__search {
		opacity: 1;
	}
	&:focus-visible .select__value,
	&:has(:focus-visible) .select__value {
		display: none;
	}
}

.select--small {
	#set.rem(height, 30);
	.select__body {
		#set.rem(height, 28);
	}

	.select__value,
	.select__search {
		#set.text(14, 16);
	}

}

.selectPopover {
	// position: absolute;
	background-color: @select[@list][bg];
	border: 1px solid @select[@list][border];
	border-radius: @select[@list][border-radius];
	box-shadow: @select[@list][box-shadow];
	// overflow-x: hidden;
	overflow-y: auto;
	// #set.rem(width, 256);
	// width: 100%;
	width: auto;
	pointer-events: auto;

	color: @color-text;


	&__label, &__sectionTitle {
		font-weight: bold;
		color: @select[@list][label];
		#set.padding(8, 10, 6);
	}

	&__items {
		// overflow-y: auto;
		// max-height: inherit;
		// pointer-events:

	}

	&__section {}

	&__item {
		#set.padding(4, 10);
		cursor: pointer;
		display: flex;
		.icon {
			margin-right: .5em;
		}
	}

	&__item.is\:selected {
		// background-color: blue;
	}

	&__item.is\:focus,
	&__item:focus {
		// outline: 1px solid @select[@list][@item][@hover][bg];
		// border: 1px solid @select[@list][@item][@hover][bg];
		color: @select[@list][@item][@focus][color];
		background-color: mix(@select[@list][@item][@focus][bg], @color-white, 20%);
	}

	&__item:hover {
		color: @select[@list][@item][@hover][color];
		background-color: @select[@list][@item][@hover][bg];

		.icon {
			color: @select[@list][@item][@hover][color];
		}
	}

	.checkbox__check {
		background-color: @select[@list][bg];
	}

	.icon {
		color: @select[@control][color];
	}
}

.selectPopover--small {
	#set.text(14, 16);

}
