
.copyMenu {
	border-radius: @ui-rounding-medium;
	overflow: hidden;
}

.copyMenuPopover {
	z-index: 400; // needs to be above modals.
	// width: var(--radix-popover-trigger-width);
	// width: var(--radix-popover-anchor-width);
	background-color: @color-bg-faded;
	max-width: .em(210)[];
	
	.contextMenu__item {
		white-space: nowrap;
	}
}