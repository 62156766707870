// main: ../build.less
@import (reference) '../config';

.markdown 
{
	// set a minimum line height but leave text sizing to the parent
	// line-height: 1.333;

	h1, h2, h3, h4, h5, h6 {
		font-weight: bold;
		* + & { margin-top: 1em; }
	}

	strong { font-weight: bold;	}
	i { font-style: italic; }
	u {	text-decoration: underline; }

	ul, ol, p { 
		* + & { margin-top: 0.5em; }
	}

	ol, ul {
		list-style: outside;
		margin-left: 1.5em;
	}
	
	li {
		padding-left: 0.25em;
		&::marker { color: @color-primary-400; }
		&:not(:first-child) { 
			margin-top: 0.5em; 
		}
	}
}