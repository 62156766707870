// main: ../build.less
@import (reference) '../config';

.viewHeader {
	background: @header[bg];
	color: @header[color];
	box-shadow: @header[box-shadow];

	&__masthead {
		visibility: hidden;
		display: none;
		padding-left: 0.75em;

		@media @fixed {
			display: flex;
			visibility: visible;
			align-items: center;
		}

		@media @desktop {
			display: flex;
			visibility: visible;
			align-items: center;
		}
	}
	&__logo {
		display: inline-flex;
		align-items: center;
		cursor: pointer;
		@media @fixed {
			width: .em(120)[];
		}
		@media @desktop {
			width: .em(160)[];
		}
		img {
			width: 100%; height: auto;
			max-width: 100%;
		}
	}
	&__tagline {
		// display: flex;
		display: none;
		flex-flow: column nowrap;
		justify-content: center;
		border-left: @header[@tagline][border];
		padding: .em(3)[] .em(8)[] .em(3)[] .em(15)[];

		@media @desktop {
			display: flex;
		}

		&Sub {
			color: @header[color];
			font-size: .em(12)[];
			line-height: 1.25;
		}
		&Title {
			display: block;
			color:@header[@tagline][color];
			font-weight: 700;
			line-height: 1.25;
		}
	}

	&__toolbar {
		// TODO: does this belong in views/app?
		// display: flex;
		// align-items: center;
		// justify-content: space-between;
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		align-items: center;

		@media @fluid {
			padding: 0.5em 0;
		}

		@media @fixed {
			padding: 0.5em;
			padding-left: 0;
		}

	}

	&__toggleOptions {
		background: none !important;
		color: @header[@toggles][color];
		padding: 0 0.75em;
		height: 100%;
		display: inline-flex;
		align-items: center;

		&::before {
			#set.text(22, 24);
		}
		
		@media @tablet {
			padding: 0 1em;
		}

		@media @fixed {
			&--options {
				display: none !important;
				visibility: hidden;
			}
		}

		@media @desktop {
			display: none !important;
			visibility: hidden;
		}

	}

	// &__toggleExtended {
	// 	width: 3em;
	// 	height: 100%;
	// 	margin-left: 0.25em;
	// 	display: inline-flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	color: @header[@toggles][color];
	// 	background: none;

	// 	.show\:extendedSearch & {
	// 		// background-color: @color-primary-200;
	// 		position: relative;
	// 		color: @header[@toggles][active];
	// 		border-top-left-radius: 0.15em;
	// 		border-top-right-radius: 0.15em;

	// 		&::before { content: @icon-arrow_drop_up; }

	// 		// &::after {
	// 		// 	content:'';
	// 		// 	width: 100%;
	// 		// 	height: 2em;
	// 		// 	// background-color: @color-primary-200;
	// 		// 	position: absolute;
	// 		// 	top: 100%;
	// 		// 	left: 0;
	// 		// }
	// 		@media @fixed {
	// 			background-color: transparent;
	// 			&:after { display: none; }
	// 		}
	// 	}
	// }

	&__search {
		&Input {
			flex: 1 1 100%;
			overflow-x: auto;
			overflow-y: hidden;
			grid-area: middle;
		}
		&Mode {
			grid-area: left;
		}
		&Submit {
			grid-area: right;
		}
		&Box {
			flex: 1 1 100%;
			height: 3.5rem;
			display: grid;
			grid-template-areas: 'left middle right';
			// grid-template-columns: minmax(0, auto) auto minmax(0, auto);
			grid-template-columns: minmax(0, auto) minmax(auto, 1fr) minmax(0, auto);
			position: relative;

			padding: 0;
			background-color: @header[@searchbox][bg];
			border: @header[@searchbox][border-width] solid @header[@searchbox][border];
			border-radius: @header[@searchbox][border-radius];

			@media @fixed {
				#set.margin(left, 4);
				#set.margin(right, 6);
				grid-template-columns: minmax(0, auto) minmax(auto, 1fr) minmax(0, 8.9rem);
			}
		}
		
		&Box.has\:proSearch .proSearch {
			display: flex;
		}
		
		&Box.has\:suggestions .searchSuggestions {
			display: block;
		}

		&Box.has\:suggestions &Submit {
			// border-bottom-right-radius: 0;
			@media @fixed {
				border-bottom-right-radius: @header[@searchbox][border-radius];
			}
		}

		&Actions {
			padding: 0.084rem 0;
			.button {
				border-radius: 0.15em;
				height: auto;

				b {
					font-weight: bold;
					#set.text(14, 14);
				}
				&.is\:active {
					background: @header[@button][@active][bg];
					color: @header[@button][@active][color];
				}

				&::before {
					color: @color-primary-400;
				}
			}

			@media @fluid {
				.toggle-visibility(~'.is\:open');

				// position: fixed;
				// z-index: @z-modal;
				right: 1em;
				top: 1em;
				left: 1em;
				padding: 1.5em 1em;
				background-color: @menu[bg] !important;
				border-radius: @menu[border-radius];
				box-shadow: @menu[box-shadow];

				.buttonGroup {
					flex-flow: column nowrap;
					width: 100%;
					margin: 0;
					// margin: 0.2em 0 0 0;
					gap: 0.2em 0;
					// background: red;

				}

				.button {
					#set.text(16, 24);
					background-color: transparent;
					color: @menu[@item][color];
					width: 100%;
					padding: 0.5em 1em;
					margin: 0.2em 0 0 0;
					// margin: 0;
					display: block;
					text-align: left;
					border-radius: @ui-rounding-controls !important;

					&::before,
					b {
						margin-right: 0.5em;
						color: @menu[@item][icon];
					}
					span {
						white-space: pre-wrap;
					}
					&.is\:active {
						background-color: @menu[@item][@hover][bg];
						color: @menu[@item][@hover][color];
						&::before,
						b {
							color: @menu[@item][@hover][icon];
						}
					}
				}
			}

			@media @fixed {
				min-height: 100%;
				align-self: stretch;
				display: flex;
				align-items: stretch;
				margin-left: auto;

				.button {
					height: 100%;
					#set.rem(width, 32);
					// padding: 0;
					padding: 0.66em 1.25em;
					justify-content: center;
					background-color: @header[@button][bg];
					color: @header[@button][color];
					border-radius: @header[@button][border-radius];
					transition: @transition[@controls][subtle];

					&::before {
						margin: 0;
						padding: 0;
					}

					b {
						#set.text(13, 13);
					}

					span {
						display: none;
					}

					&.is\:active {
						background-color: @menu[@item][@hover][bg];
						color: @menu[@item][@hover][color];
						&::before,
						b {
							color: @color-white;
						}
					}

					&:not(.is\:active):hover {
						background-color: @header[@button][@hover][bg];
						color:  @header[@button][@hover][color];
						&::before { color:  @header[@button][@hover][color]; }
					}
				}
				.buttonGroup {
					height: 100%;
					align-items: stretch;
					background-color: @header[@button][bg];
					border-radius: @header[@button][border-radius];

					.button {
						border-radius: 0;
						&:first-of-type {
							border-top-left-radius: @header[@button][border-radius];
							border-bottom-left-radius: @header[@button][border-radius];
						}
						&:last-of-type {
							border-top-right-radius: @header[@button][border-radius];
							border-bottom-right-radius: @header[@button][border-radius];
						}
					}
				}
			}
		}

		// &FilterTags {
		// 	flex-shrink: 0;
		// 	display: flex;
		// 	flex-flow: row nowrap;

		// 	@filterTag-border-radius: 3;

		// 	.filterTag {
		// 		margin-right: .5em;
		// 		cursor: pointer;

		// 		&__field {
		// 			font-weight: bold;
		// 			text-transform: capitalize;
		// 			background: @color-primary-500;
		// 			padding: 0.333em 0.25em 0.333em .375em;
		// 			#set.rem(border-top-left-radius, @topicLabel-border-radius);
		// 			#set.rem(border-bottom-left-radius, @topicLabel-border-radius);
		// 		}

		// 		&__value {
		// 			background-color: @color-primary-400;
		// 			padding: 0.333em 0.375em;
		// 			#set.rem(border-top-right-radius, @topicLabel-border-radius);
		// 			#set.rem(border-bottom-right-radius, @topicLabel-border-radius);
		// 		}
		// 	}


		// }
	}

}
