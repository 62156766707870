@import (reference) '../config';

.datePickerDialog {
	overflow-y: auto;
	padding: 1em;
	
	.dialog__title {
		padding-left: 0.25em;
	}
	
	.dialog__close {
		display: block;
		background-color: fade(@color-text-faded, 20%);
		color: @color-primary-faded;
		height: 3rem;
		width: 3rem;
		position: absolute;
		top: 1em;
		right: 1em;
		border-radius: 50%;
		transition: all 0.3s;
		font-weight: 900;
		
		&:focus {
			outline: none;
			background-color: fade(@color-text-faded, 40%);
		}
		
		&::before {
			font-size: 1.5em;
			font-weight: 900;
		}
		
		&:hover {
			color: @color-primary;
			background-color: fade(@color-text-faded, 40%);
		}
	}
}

.datePicker {
	display: inline-block;
	// max-width: 100%;
	font-size: @input[font-size];
	width: 100%;
	position: relative;
	border-color: @color-primary;

	&,
	&__body,
	&__input {
		border-radius: @input[border-radius];
	}
	&__body {
		display: flex;
		border-radius: @input[border-radius];
		min-height: @input[height];

		&:focus,
		&:focus-within {
			border-color: @color-primary-600;
		}
	}
	&__label {
		display: none;
		@media @fixed {
			display: block;
			padding-right: 1em;
		}
	}
	&__input {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		width: 100%;
		line-height: 1;
		margin: 1.5em 0 1.25em;
		.font--small;
		@media @tablet {
			.font--medium;
		}
		@media @fixed {
			height: @input[height];
			flex-flow: row nowrap;
			justify-content: space-around;
			align-items: baseline;
			margin: 0;
		}
	}
	
	&__inputWrapper {
		display: flex;
		flex-flow: row wrap;
		align-items: baseline;
	}
	
	&__inputDateSegments {
		display: flex;
		flex-flow: row nowrap;
	}

	&__inputDate {
		display: flex;
		flex-flow: row nowrap;
	}

	&__inputSeparator {
		#set.rem(width, 24);
		text-align: center;
	}

	.segment {
		padding: 0;
		font-variant-numeric: tabular-nums;
		text-align: end;
		align-self: baseline;
		
		// &:invalid {
		// 	color: @color-text-error !important;
		// 	background-color: @color-bg-error !important;
		// }
		
		// positiones the separator dots with the number to the left.
		&[aria-hidden="true"] {
			position: relative;
			left: -0.5em;
			pointer-events: none;
		}
		
		&[role="spinbutton"] {
			min-width: 2rem;
			#set.padding( 6, 10 );
			border-radius: @ui-rounding-minimal;
			background-color: desaturate( mix(@color[primary-100], @color-bg, 20%), 35%);
			// outline-offset: 1px;
		}
		&[role="spinbutton"]:hover {
			background-color: desaturate( mix(@color[primary-100], @color-bg, 60%), 35%);
		}
		&[role="spinbutton"]:focus {
			// outline: 1px solid @color-primary-600 !important;
			// #5f82f3
			color: @color-white;
			background-color: @color-primary-400;
		}
		
		// no clue why this is not working.
		&[role="spinbutton"]:focus + &[aria-hidden="true"] {
			color: @color-white;
		}
	}
	
	.segment[role="spinbutton"]:focus + .segment[aria-hidden="true"] {
		color: @color-white;
	}

	&__placeholder {
		// color: @input-placeholder-color;
		width: 100%;
		color: @input[placeholder];
		height: @input[height];
		line-height: 1;
		padding: @input[@padding][vertical] @input[@padding][horizontal];
	}
	&__toggle {
		background: transparent !important;
		#set.rem(width, 32);
		height: 100%;
		color: @input[@icon][color];
		border: none;
	}
	&__toggle:focus {
		outline: none;
		// outline: 1px solid @color-primary-600;
		// outline-offset: 1px;
		// background-color: @color-primary-300;
		// color: @color-primary-400;
	}
}

// @datepickerSelector: .calendar;
// @calendar-border-color: @color-secondary;
// @calendar-border-color: @input[border];
// @calendar-border-color: desaturate( mix(@color[primary-100], @color-bg, 60%), 35%);
@calendar-grid-color: desaturate( mix(@color[primary-100], @color-bg, 80%), 50%);

.calendar {
	width: auto;
	border-radius: @input[border-radius];

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		#set.padding( 3, 0 );
	}
	&__body {
		display: flex;
		gap: 1em;
	}
	&__table {
		width: 100%;
	}
	&__currentMonth {
		font-weight: bold;
	}
	&__navButton {
		#set.size( 30, 30 );
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		background-color: transparent;
		color: @color-primary;
		font-weight: bold;
		border-radius: @ui-rounding-minimal;

		&:hover {
			background-color: transparent;
		}
		
		&.is\:disabled {
			color: @color-text-faded;
			cursor: default;
		}
	}

	&__columnLabels,
	&__days {
		display: grid;
		grid-template-columns: repeat(7, 14%);
		#set.rem( grid-auto-rows, 32 );
		text-align: center;
		@media @fixed {
			grid-template-columns: repeat(7, 3.0rem);
		}
	}
	&__columnLabels > * {
		#set.text( 14, 30 );
		text-transform: uppercase;
		color: @color-text-faded;
	}
	&__days {
		#set.border( top, 1, solid @calendar-grid-color);
	}
	&__day {
		height: 100%;
		#set.text( 16, 30 );
		cursor: pointer;
		&:nth-child(7n) {
			border-right: none;
		}

		
		&.is\:disabled {
			color: @color-text-faded;
			cursor: default;
		}
		
		// &.is\:unavailable {
		// 	color: @color-text-faded;
		// 	cursor: not-allowed;
		// }
		// &.is\:invalid {
		// 	color: @color-text-faded;
		// 	cursor: not-allowed;
		// }

		&.is\:selected {
			// font-weight: bold;
			color: @color-white;
			background-color: @color-primary-300;
			// border-color: @color-secondary;
			cursor: default;
		}
		&:hover {
			background-color: @color-primary-400;
			color: @color-white;
		}
		&.is\:focus {
			// font-weight: bold;
			background-color: @color-primary-400;
			color: @color-white;
		}
		
		// &.is\:anchor {
		// 	font-weight: bold;
		// }

		&--otherMonth {
			background-color: mix(@color-bg, @color-bg-faded, 70%);
			//color: @color-text-faded;
		}

		&.is\:disabled,
		.is\:disabled:hover {
			background-color: mix(@color-bg, @color-bg-faded, 70%);
			color: @color-text-faded;
		}
	}
}


.datePickerMenu {
	// max-width: max-content;
	// width: 100%;
}

// .date-range-picker {

//   .wrapper {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//   }

//   .field {
//     display: inline-flex;
//     padding: 2px 4px;
//     border-radius: 2px;
//     border: 1px solid var(--gray);
//     background: var(--spectrum-global-color-gray-50);
//     max-width: 100%;
//     overflow: auto;
//   }

//   .field:focus-within {
//     border-color: var(--blue);
//   }

//   .field .field {
//     all: initial;
//     display: inline-flex;
//     color: inherit;
//   }

//   .segment {
//     padding: 0 2px;
//     font-variant-numeric: tabular-nums;
//     text-align: end;
//   }

//   .segment.placeholder {
//     color: var(--react-spectrum-datepicker-placeholder-color);
//   }

//   .segment:focus {
//     color: white;
//     background: var(--blue);
//     outline: none;
//     border-radius: 2px;
//   }

//   .calendar {
//     width: 220px;
//   }

//   .header {
//     display: flex;
//     align-items: center;
//     gap: 4px;
//     margin: 0 8px;
//   }

//   .header h2 {
//     flex: 1;
//     margin: 0;
//   }

//   .calendar table {
//     width: 100%;
//   }

//   .cell {
//     cursor: default;
//     text-align: center;
//   }

//   .unavailable {
//     color: var(--spectrum-global-color-red-600);
//   }

//   .disabled {
//     color: gray;
//   }

//   .popOver {
//     background-color: white;
//     position: absolute;
//     margin-top: 4px;
//     padding: 20px;
//     z-index: 1;
//     color: @color-primary-muted;
//     border: 1px solid @color-primary-faded;
//     border-radius: 0.25em;

//     .selected {
//       background: @color-primary-muted;
//       color: white;
//     }

//     button {
//       color: @color-primary-muted;
//     }
//   }
// }