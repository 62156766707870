 // main: ../build.less
@import (reference) '../config';

.view {
	&--login {}

}

.login {
	width: 100%;
	#set.rem(max-width, 400);
	#set.margin(bottom, 32);
	#set.padding(52);

	&__logo {
		max-width: 16rem;
		display: block;
		#set.margin(bottom, 30);
	}

	&__title {
		#set.text(20, 18);
		font-weight: 600;
	}

	&__description {
		#set.text(14, 16);
		#set.margin(10, 0, 20, 0);
		font-weight: 500;
	}

	&__form {
		display: flex;
		flex-flow: column;
		align-items: stretch;
		// #set.margin(top, 16);
	}

	.field {
		display: flex;
		flex-flow: column;
		#set.margin(bottom, 10);
	}

	// .form__errors {
	// 	color: red;
	// }

	.field__label {
		#set.text(12, 16);
		#set.padding( 4, 0, 4 );
		color: @color-black-700;
		font-weight: 600;
	}

	.field__input {
		background: fadeout(@color-white, 90%);
	}

	&__submit {
		#set.margin(top, 16);
		background: @color-primary-700;
		border-radius: 0.5rem;

		&:hover {
			background: @color-primary;
		}

		&:focus {
			background: @color-primary;
			outline: 1px solid @color-primary;
			outline-offset: 0.1em;
		}
	}

	@media @mobile {
		#set.padding(24);
		#set.margin(bottom, 0);
	}

	@media @tablet {
		#set.margin(top, 24);
	}

}

.loginIntro {
	width: 100%;
	#set.rem(max-width, 400);
	#set.margin(bottom, 32);
}

.loginSocials {
	#set.padding(32, 32, 32, 32);

	&__title {
		#set.text(20, 18);
		font-weight: 600;
	}

	&__items {
		display: flex;
		flex-flow: row wrap;

		.button:focus {
			// background: @color-positive;
			outline: 1px solid @color-primary;
			outline-offset: 0.1em;
		}
	}

	&__item {
		#set.margin(0, 16, 0, 16);
	}

	&__logo {
		#set.rem(height, 24);
	}
}

