// main: ../index.less
@import (reference) '../config.less';

.spinner {
	height: 6rem;
	width: 6rem;

	display: flex;
	justify-content: center;
	align-items: center;

	overflow: hidden;
	margin: 0 auto;
	pointer-events: none;

	&:before {
		position: absolute;
		height: 3.5rem;
		width: 3.5rem;

		content: '';
		color: white;
		background: transparent;
		border-radius: 50%;
		border: 0 solid transparent;

		border-width: 1pt;
		animation: spinnerDefault 4.2s infinite linear;
	}

	&:after {
		position: absolute;
		height: 2.33rem;
		width: 2.33rem;

		content: '';
		color: white;
		background: transparent;
		border-radius: 50%;
		border: 0 solid transparent;

		border-width: 1pt;
		animation: spinnerDefault 2.4s infinite linear;
	}
}

@keyframes spinnerDefault {
	25% {
		transform: rotatez(180deg);
		border-color: transparent @color-primary-800 transparent @color-primary-200;
	}
	50% {
		transform: rotatez(270deg);
		border-color: transparent @color-primary-400 transparent @color-primary-600;
	}
	100% {
		transform: rotatez(360deg);
	}
}

@keyframes spinnerDefaultInverted {
	25% {
		transform: rotatez(180deg);
		border-color: transparent @color-primary-600 transparent @color-primary-100;
	}
	50% {
		transform: rotatez(270deg);
		border-color: transparent @color-primary-200 transparent @color-primary-400;
	}
	100% {
		transform: rotatez(360deg);
	}
}

.spinner.spinner--inverted {
	&:before {
		animation: spinnerDefaultInverted 4.2s infinite linear;
	}
	&:after {
		animation: spinnerDefaultInverted 2.4s infinite linear;
	}
}

.spinner--large {
	height: 12rem;
	width: 12rem;

	animation: pulse 4.2s infinite ease-in-out;

	&:before {
		height: 8rem;
		width: 8rem;
		border-width: 1pt;
		animation: spinnerLarge 4.2s infinite linear;
	}

	&:after {
		position: absolute;
		height: 6rem;
		width: 6rem;
		border-width: 1pt;
		animation: spinnerLarge 2.4s infinite linear;
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.85);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes spinnerLarge {
	25% {
		transform: rotatez(180deg);
		border-color: transparent @color-primary-400 transparent @color-primary-600;
	}
	50% {
		transform: rotatez(270deg);
		border-color: transparent @color-primary-600 transparent @color-primary-400;
	}
	100% {
		transform: rotatez(360deg);
	}
}

.spinner--inline {
	display: inline-flex;
	top: 0.2rem;
}

.spinner--small {
	position: relative;
	// left: -0.5rem;
	// display:
	height: 1.4rem;
	width: 1.4rem;
	// transform: rotatez(90deg);
	&:before {
		// position: absolute;
		// top: 0.2rem;
		height: 0.95rem;
		width: 0.95rem;

		border-width: 0.15rem;
		// animation: spinnerSmall 2s infinite linear;
		animation: spinnerSmallInverted 2s infinite linear;
	}
	
	&:after {
		// position: absolute;
		// top: 0.2rem;
		height: 0.95rem;
		width: 0.95rem;
		border-width: 0.15rem;
		// animation: spinnerSmall 4s infinite linear;
		animation: spinnerSmallInverted 4s infinite linear;
	}
}

@keyframes spinnerSmall {
	25% {
		transform: rotatez(180deg);
		border-color: transparent @color-primary-700 transparent @color-primary-700;
	}
	50% {
		transform: rotatez(270deg);
		// border-color: @color-secondary transparent @color-secondary transparent;
		border-color: transparent @color-primary-300 transparent @color-primary-300;
	}
	100% {
		transform: rotatez(360deg);
	}
}

@keyframes spinnerSmallInverted {
	25% {
		transform: rotatez(180deg);
		border-color: transparent @color-primary-100 transparent @color-primary-100;
	}
	50% {
		transform: rotatez(270deg);
		// border-color: @color-secondary transparent @color-secondary transparent;
		border-color: transparent @color-primary-300 transparent @color-primary-300;
	}
	100% {
		transform: rotatez(360deg);
	}
}

.spinner--small.spinner--inverted {
	&:before {
		animation: spinnerSmallInverted 2s infinite linear;
	}
	&:after {
		animation: spinnerSmallInverted 4s infinite linear;
	}
}