
.toasts {
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 400;
	// #set.rem(width, 200);
	// width: auto;
	width: 100%;
	// height: 20rem;
	// #set.rem(height, 20);
	// pointer-events: none;
		display: flex;
}

.toast {
	position: absolute;
	// display: flex;
	#set.rem(right, 16);
	#set.rem(bottom, 16);

	#set.padding(8, 8, 8, 4);
	box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
	background-color: @color-bg;
	transition: all 230ms cubic-bezier(.21,1.02,.73,1);

	// pointer-events: unset;

	&__content {
		// #set.padding(8, 8, 8, 4);
		// box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.3);
		// background-color: @color-bg-faded;
		// // transition: all 230ms cubic-bezier(.21,1.02,.73,1);
	}

	&--success {
		background-color: @color-bg-success;
		color: @color-text-success;
	}
	&--error {
		background-color: @color-bg-error;
		color: @color-text-error;
	}

	&__message {
			#set.padding(0, 8);
		// #set.padding(left, 8);
	}
	&__message::before {
		#set.margin(right, 8);
	}
	&__actions {}
	&__action {}


}

