// main: ../../build.less
#icon()
{
	// printing a single icon selector & applying content value
	.register ( 
		@handle, 
		@namespace: icon,
		@delimiter: \:
	){
		@_iconGlyph: ~"@{namespace}-@{handle}";
		@_iconClass: ~".@{namespace}@{delimiter}@{handle}";
		
		@{_iconClass}::before { 
			content: @@_iconGlyph; 
		}
	}
	// recursively print list of icon selectors
	.registerList (
		@list,
		@namespace: icon,
		@delimiter: \:,
		@x: 1
	) when ( @x <= length( @list )) 
	{
		@_iconName: extract( @list, @x );

		#icon.register( @_iconName, @namespace, @delimiter );

		#icon.registerList( @list, @namespace, @delimiter, ( @x + 1 ));
	}

	// WIP: apply an icon to an element by classname
	// could also be achieved with: .iconLike; .icon--name; ?
	.set(
		@icon-name,
		@size: 16,
		@color: currentColor,
		@spacing: 0,
		@position: before
	) {
		@_icon-glyph: ~"icon-@{icon-name}";
		@_position: ~"::@{position}";

		&@{_position}
		{

			&:extend(.iconLike); // TODO: wtf happened to iconLike?
			font-family: '@{__ICON_FONT_NAME}';

			content: @@_icon-glyph;
			color: @color;
			#set.text( @size, @size );

			& when ( @position = before ) {
				#set.margin( right, @spacing );
			}
			& when ( @position = after ) {
				#set.margin( left, @spacing );
			}
		}
	}
	
	// WIP: intended to bind class IF element has an icon
	// not sure if needed but avoids typing out selector
	.has( 
		@rules,
		@namespace: @iconNamespace,
		@delimiter: @iconDelimiter
	) {
		&[class*="@{namespace}@{delimiter}"] { @rules(); }
	}
}
