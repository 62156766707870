// main: ../build.less
@import (reference) '../config';

.sourceBadge {
	display: inline-flex;


	&__published,
	&__host {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: @badge[height];
		white-space: nowrap;
	}

	&__published {
		min-width: 2.5em;
		padding: 0 0.45em 0 0.667em;
		font-weight: bold;
		background-color: @badge[@marker][bg];
		color: @badge[@marker][color];
		border-top-left-radius: @badge[border-radius];
		border-bottom-left-radius: @badge[border-radius];

		// &:hover {
		// 	text-decoration: underline;
		// }
	}

	&__host {

		#set.margin(left, 1);
		padding: 0 1em 0 0.667em;
		font-weight: 500;
		background-color:  @badge[bg];
		color:  @badge[color];
		border-top-right-radius: @badge[border-radius];
		border-bottom-right-radius: @badge[border-radius];

		// span:hover {
		// 	text-decoration: underline;
		// }
	}

	&__domain {
		cursor: pointer;
	}

	&__domain:hover {
		text-decoration: underline;
	}
	&__author {

	}

	&__author.is\:filterable:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}