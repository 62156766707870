// main: ../../index.less
@import (reference) "../../config.less";

// COMPONENT DEFAULTS:
// --------------------------------------------------------------------------
@buttonSelectorName: button;
@buttonSelector: .button;

@buttonIconOnlySelector: .has\:iconOnly;

@button-color: #fff;
@button-background: #444;
@button-hover-color: #fff;
@button-hover-background: #333;
@button-border: none;
@button-border-radius: 3;
@button-padding-vertical: 8;
@button-padding-horizontal: 24;
@button-font-size: 16;
@button-icon-size: 20;
@button-height: 36;
@button-adjacent-spacing: 1;

@button-simulate-press: 1;

// GLOBAL STYLES:
// --------------------------------------------------------------------------
button { 
	border: none; 
	font-family: @__FONT_NAME;
}

@{buttonSelector} 
{
	&:extend( .buttonLike all );
}


.buttonLike 
{
	display: inline-flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;

	font-family: @__FONT_NAME;
	cursor: pointer;

	backface-visibility: hidden;
	transform: translate3d(0, 0, 0);
	transition: 
		transform .1s ease-out,
		color .15s ease-out,
		background .25s ease-out,
		border-color .25s ease-out;
	// TODO better integrate transition options

	// this setting will likely stay the same on all variants but is overrideable in the mixins
	#set.rem( border-radius, @button-border-radius);

	a& { text-decoration: none; }

	button& { }

	&:hover  { }

	&:focus  { outline: 0; }
	// TODO: generalizing focus styles to not have to cram #button.variant
	
	&:active {
		& when ( @button-simulate-press > 0 ) {
			transform:  translate3d(0, unit( (@button-simulate-press /  @__REM_SCALING), rem), 0);
		}
	} 

	&:not( .has\:iconOnly )::before { 
		margin-right: 0.5em; 
		margin-left: -0.25em;
	}

	&.has\:iconOnly {
		padding: 0 !important; // as to not need to overwrite paddings in every #button.size
		&::before { margin: 0; }
	}

	// & + & { #set.margin( left, @button-adjacent-spacing); }
}


// VARIANT CONSTRUCTORS:
// --------------------------------------------------------------------------
#button() 
{		
	.size (
		@selectorExtension: '',
		@font-size: 		@button-font-size,
		@icon-size: 		@button-icon-size,
		@height: 			@button-height,
		@padding-left: 		@button-padding-horizontal,
		@padding-right: 	@button-padding-horizontal,
		@border-radius: 	none,
	) {
		
		@__variantSelector: ~"@{buttonSelector}@{selectorExtension}";

		@{__variantSelector}
		{
			#set.text( @font-size, @font-size );
			#set.rem( height, @height );

			& when (isnumber( @icon-size )) {
				&::before { #set.text(@icon-size, @icon-size); }
			}

			& when (isnumber( @padding-left )) { 
				#set.padding( left, @padding-left ); 
			}

			& when (isnumber( @padding-right )) { 
				#set.padding( right, @padding-right ); 
			}

			& when (isnumber( @border-radius )) { 
				#set.rem( border-radius, @border-radius ); 
			}
			
			// &.has\:iconOnly { 
			// 	#set.rem( width, @height );
			// }
		}
	}
	.variant(
		@selectorExtension: '',
		@background: 		@button-background,
		@background-hover: 	@button-hover-background,
		@background-active:	@button-hover-background,
		@color: 			@button-color,
		@color-hover: 		@button-hover-color,
		@color-active: 		@button-hover-color,
		@icon-color: 		none,
		@icon-hover: 		none,
		@icon-active: 		none,
		@border: 			0,
		@border-color:		none,
		@border-hover:		none,
		@border-active:		none,
		@border-radius: 	none,
	) {
		@__variantSelector: ~"@{buttonSelector}@{selectorExtension}";

		@{__variantSelector}
		{
			background: @background;
			color: @color;

			& when ( iscolor( @icon-color )) {
				&::before { color: @icon-color; }
			}

			& when ( @border > 0 ) {
				#set.rem( border-width, @border );
				border-style: solid;
				border-color: @border-color;
			}

			& when ( isnumber( @border-radius )) { 
				#set.rem( border-radius, @border-radius ); 
			}

			// &:focus, // is this a good shortcut to maintain focus state?
			&:hover
			{
				background: @background-hover;
				color: @color-hover;

				& when ( @border > 0 ) 
				  and ( iscolor( @border-color ) ) {
					border-color: @border-hover; 
				}
				
				& when ( iscolor( @icon-hover )) {
					&::before { color: @icon-hover; }
				}
			}

			&:active,
			&.is\:active 
			{
				background: @background-active;
				color: @color-active;

				& when ( @border > 0 ) 
				  and ( iscolor( @border-color ) ) {
					border-color: @border-active; 
				}

				& when ( iscolor( @icon-active )) {
					&::before { color: @icon-active; }
				}
			}
		}
	}
}