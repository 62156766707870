// main: ../build.less
@import (reference) "../config";

.checkbox {
	display: inline-flex;
	cursor: pointer;
	user-select: none;
	align-items: center;

	&__check {
		#set.text(18, 16);
		border-color: @checkbox[border];
		border-style: solid;
		border-radius: @checkbox[border-radius];
		border-width: @input[border-width];
		#set.rem( border-width, 1 );
		height: 1em;
		width: 1em;
		min-height: 1em;
		min-width: 1em;
		display: inline-flex;
		justify-content: center;
		// align-items: center;
		// line-height: 1.5rem;
		margin-right: 1rem;
	}

	&__label {
		font-weight: bold;
		color: @color-text-muted;
	}

	&.is\:selected &__check,
	&.is\:indeterminate &__check
	{
		background-color: @checkbox[@selected][bg];
		color: @checkbox[@selected][color];
		border-color: @checkbox[@selected][border];;
	}

	&.is\:selected &__label {
		color: @color-text;
	}
}

.checkbox--small {
	.checkbox__check {
		height: 1.5rem;
		width: 1.5rem;
		min-width: 1.5rem;
		#set.text(16, 14);
		margin-right: 1rem;
		// line-height: 1.5rem;
	}
}




// .checkbox {
// 	// border: @input-border-width solid @color-secondary-muted;
// 	// border-radius: @input-border-radius;
// 	border-color: @color-secondary-muted;
// 	border-style: solid;
// 	#set.rem( border-radius, @input-border-radius );
// 	#set.rem( border-width, 3 );
// 	height: 2rem;
// 	width: 2rem;
// 	min-width: 2rem;
// 	display: flex;
// 	justify-content: center;
// 	// align-items: center;
// 	line-height: 1.5rem;

// 	&.is\:selected {
// 		border-color: @color-secondary-muted;
// 		background-color: @color-secondary-muted;
// 	}
// }
