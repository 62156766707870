// main: ../build.less
@import (reference) '../config';


@property --angle {
	syntax: '<angle>';
	initial-value: 0deg;
	inherits: true;
}
@keyframes rotate-angleprop-clockwise {
	0% { --angle: 0deg; }
	100% { --angle: 360deg; }
}
@keyframes rotate-element-clockwise {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

@keyframes summaryCard-enter {
  from {
    opacity: 0;
		// transform: scale(0.9);
		// transform: translateY(6rem);
  }
  to {
    opacity: 1;
		// transform: scale(1);
		// transform: translateY(0);
  }
}

.summaryCard
{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;

	background: @color-bg;
	box-shadow: @widget[box-shadow];
	border-radius: @widget[border-radius];
	
	// enter animation
	// transform: scale(0.9);
	// transform: scale(0.9);
	opacity: 0;
	animation: summaryCard-enter 0.2s ease-in forwards;
	
	// You know its SERIOUSLY COOL SHIT when Firefox aint supporting it 😎
	position: relative;
	&::before {
		content: "";
		display: inline-block;
		position: absolute;
		pointer-events: none;
		inset: 0;
		border-radius: @widget[border-radius]; 
		padding: 2px; 
		background:linear-gradient( var(--angle), @color-bg, @color-primary-400); 
		-webkit-mask: 
			linear-gradient(#fff 0 0) content-box, 
			linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
				mask-composite: exclude; 
		animation: rotate-angleprop-clockwise 3.5s linear infinite forwards;
		opacity: 0;
		transition: opacity 1s ease-in-out;
	}
	&.is\:writing::before { opacity: 1; }


	&__header {
		padding: .em(20)[] .em(24)[] .em(0)[] .em(28)[];
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		
		&Actions {
			display: flex;
			flex-flow: row wrap;
			align-self: flex-start; 
		}

		&Action { 
			&::before { transition: color 600ms ease-out; }
		}
		
		.spinner {
			height: 1.75em;
		}
		
		&Menu {
			background-color: transparent;
			color: @color-primary-500;
			height: 2em;
			transition: color 600ms ease-out;
			border-radius: @ui-rounding-controls;
			
			&.is\:active {
				background-color: @color-primary-400;
			}
			
			&.is\:active:before {
				color: @color-bg;
			}
		}
	}
	&.is\:writing &__headerAction::before,
	&.is\:writing &__headerMenu::before {
		color: @color-primary-400;
		// animation: rotate-element-clockwise 2.5s linear infinite;
	}

	&__title {
		
		transition: opacity 1s ease-out;
		margin-left: .em(-8)[];

		> a { 
			color: @color-primary-600;
			text-decoration: none;
			&:hover {
				color: @color-primary-400;
				text-decoration: underline;
			}
		}
		&Stream {
			font-size: .em(19)[];
			color: @color-primary-600;
			line-height: 1;
			font-weight: bold;
			display: flex;
			align-items: center;
			gap: .em(6)[];
		}
		&Icon {
			width: .em(24, 21)[];
		}
		&Sub {
			font-size: .em(14)[];
			color: @color-primary-300;
			margin-left: .em(28, 14)[];
		}

	}


	&__body {
		padding: .em(12)[] .em(24)[] .em(12)[];
		justify-self: stretch;
		flex: 1 1 100%;
		overflow: hidden;
		opacity: 1;
		transition: opacity 1s ease-out;
	}
	&.is\:writing &__body { opacity: 0.6;}

	&__bodyText {
		font-size: .em(14)[];
		line-height: 1.5;
	}

	&__footer {
		// margin-top: auto;
		display: flex;
		justify-content: center;
		padding: .em(8)[] .em(24)[] .em(18)[];

		> a {
			color: @color-primary-300;
			text-decoration: none;
			&:hover {
				color: @color-primary-500;
				text-decoration: underline;
			}
		}
	}


	//-- VARIANT: Settings Box
	&--settings {
		justify-content: center;
		align-items: center;
		padding: .em(48)[] 0;
		position: relative;
		animation: none;
		opacity: 1;

		> * { 
			width: 80%; 
			padding: .em(8)[] .em(24)[];
		}
	}
	
	&--settings &__header {
		// position: absolute;
		// top: 0;
		// right: 0;
	}
	
	&--settings &__deleteAction {
		width: auto;
		position: absolute;
		left: .em(8)[];
		top: .em(18)[];
	}
	
	&--settings &__body,
	&--settings &__footer {
		padding: 0 .em(24)[];
	}
	&--settings &__body {
		flex: 0 1 auto;
		> .select {
			width: 100%;
			margin-top: .em(8)[];
		}
	}
	&--settings &__footer {
		margin-top: .em(8)[];
		> .button { flex: 1 1 100%; }
	}


	//-- VARIANT: Placeholder / Add Button
	&--placeholder {
		justify-content: center;
		align-items: center;
		border: 2px dashed @color-primary-300;
		background-color: transparent;
		box-shadow: none;
		transition: background-color .3s ease-in-out;

		&:hover {
			background-color: fade(@color-primary-100, 50%);
		}
	}
	&__placeholderAction {
		flex-flow: column nowrap;
		align-items: center;
		height: auto;
		color: @color-primary-200;
		width: 100%;
		height: 100%;
		padding: .em(48)[] .em(24)[];
		text-align: center;
		border-radius: @widget[border-radius];

		@media @fixed {
			padding: 25% .em(24)[];
		}

		&::before {
			font-size: .em(48)[];
			margin-right: 0 !important;
			margin-bottom: .em(8, 48)[];
		}
		&:hover {
			color: @color-primary-500;
		}
		&:focus {
			border: none;
			outline: none;
			background-color: fade(@color-primary-100, 50%) !important;
		}
	}
	
	// offsets so cards fade in one after another
	&:nth-child(1) {
		// animation-delay: 0.2s;
	}
	&:nth-child(2) {
		animation-delay: 0.3s;
	}
	&:nth-child(3) {
		animation-delay: 0.6s;
	}
	&:nth-child(4) {
		animation-delay: 0.9s;
	}
	&:nth-child(5) {
		animation-delay: 1.2s;
	}
	&:nth-child(6) {
		animation-delay: 1.5s;
	}
	
	&.is\:mounted {
		animation: none;
		opacity: 1;
	}
	
	&.is\:writing .summaryItemContent:hover .summaryItemContent__summary {
		text-decoration: none;
	}

}

.summaryItem {
	// prevents the popover mouseleave event from firing when moving the mouse from the trigger to the popover
	position: relative;
	&:hover { z-index: 400; }
	// keep summary expanded to 4 lines while popover open
	&.is\:open .summaryItemContent__summary {
		-webkit-line-clamp: 4;
		cursor: pointer;
	}
}

.summaryItemContent {
	padding-left: .em(26)[];
	&:hover &__summary {
		-webkit-line-clamp: 4;
		cursor: pointer;
		text-decoration: underline;
	}
	&__title {
		font-weight: bold;
		display: none;
		padding-right: .em(6)[];
		// &:after {
		// 	content: " •";
		// 	// position: absolute;
		// 	// left: .em(30)[];
		// }
	}
	
	&__summary {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		&:before {
			content: "•";
			position: absolute;
			left: .em(2)[];
		}
	}
}

.summaryItem + .summaryItem .summaryItemContent {
	padding-top: 0.5em;
}

.summaryItemPopover {
	outline: none;
	width: var(--radix-popover-trigger-width);
	font-size: .em(12)[];
	padding: 1em;
	background-color: @tooltip[bg];
	color: @tooltip[color];
	box-shadow: @tooltip[box-shadow];
	border-radius: @tooltip[border-radius];

	&__title {
		font-weight: bold;
		margin-bottom: 0.5em;
	}
	
	&__headline {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	
	&__headline + &__headline {
		margin-top: 0.5em;
	}
	
	&__url {
		overflow: hidden;
		text-overflow: ellipsis;
	}
	
	.sourceBadge {
		pointer-events: none;
		font-size: 0.85em;
		margin-right: 0.5em;
	}
}