//main: ../build.less
@import (reference) '../config';

.dialogOverlay {
	z-index: @z-modal - 1;
	background: @dialog[@overlay][bg];
	position: fixed;
	inset: 0px;
	animation: 150ms cubic-bezier(0.16, 1, 0.3, 1) 0s 1 normal none running
		dialog-overlay-enter;
}

.dialog {
	position: fixed;
	z-index: @z-modal;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	
	width: 90vw;
	max-width: @dialog[max-width];
	max-height: 85vh;
	padding: @dialog[@padding][vertical] @dialog[@padding][horizontal];

	background-color: @dialog[bg];
	border-radius: @dialog[border-radius];
	box-shadow: @dialog[box-shadow];
	animation: 150ms cubic-bezier(0.16, 1, 0.3, 1) 0s 1 normal none running
		dialog-enter;

	&__title {
		font-weight: bold;
		.font--medium;
	}
	&__description {
	}
	&__content {
		margin-top: 1.5rem;
		input, .select { width: 100%; }
	}
	&__actions {
		display: flex;
		justify-content: center;
		margin-top: 1em;
	}
	
	&__action + &__action {
		margin-left: 0.75em;
	}
}




// Animations
@keyframes dialog-overlay-enter {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes dialog-enter {
	0% {
		opacity: 0;
		transform: translate(-50%, -48%) scale(0.96);
	}

	100% {
		opacity: 1;
		transform: translate(-50%, -50%) scale(1);
	}
}

