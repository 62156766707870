// main: ../build.less
@import (reference) '../config';

.viewControls {

	&__menu {
		display: flex;
		justify-content: space-evenly;
		padding: 0.5em 0;
	}

	&__menuButton {
		//flex: 1 0 33%;
		color: @color-primary-400;
		background-color: transparent;

		&.is\:active {
			color: @color-primary-500;
		}
		&::before {
			margin-right: 0.5em;
		}
	}
}
