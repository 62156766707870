// out: false

.toggle-visibility ( 
	@selector: ~'.is\:open',
	@display_state: block
) {
	display: none;
	visibility: hidden;

	&@{selector} {
		display: @display_state;
		visibility: visible;
	}
}