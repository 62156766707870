// main: ../build.less
@import (reference) "../config";

body {
	font-family:  @font-family;
	background-color: @window[@bg][color];
	color: @color-text;
}

a:not(.blockLink) { color: @link[color]; }
a:not(.blockLink):hover { color: @link[@hover][color]; }

a.blockLink {
	color: inherit;
	text-decoration: inherit;
}

.timestamp {
	letter-spacing: +0.05em;
	b { font-weight: bold; opacity: 0.25; }
}

strong { font-weight: bold; }

.nobr { white-space: nowrap; }

.font--label  { #set.text( 15, 19 ); }

.font--mini    { #set.text( 12, 16 ); }
.font--small   { #set.text( 14, 18 ); }
.font--normal  { #set.text( 16, 24 ); }
.font--medium  { #set.text( 18, 24 ); }
.font--large   { #set.text( 21, 28 ); }
.font--heading { #set.text( 28, 36 ); }
.font--display { #set.text( 40, 60 ); }

.bigNumber {
	font-weight: 900;
	#set.text( 42, 42 );
}