// main: ../build.less
@import (reference) '../config';

.view {
	&--dashboard {
	}

	&--dashboard &Body {
	}
	
	&--dashboard &Main {
	}
	
	&--dashboard .viewHeader__searchBox {
		margin-right: .em(10)[];
	}
	
	@media @fluid {
		&--dashboard &Body {
			grid-template-columns: 95vw 100vw;
		}
	}
	
	@media @fixed {
		&--dashboard &Body {
			grid-template-columns: 25em 100vw;

		}
	}
	@media @desktop {
		&--dashboard &Body {
			grid-template-columns: 25em 1fr;
		}
	}

}
