// main: ../build.less
@import (reference) '../config';

.summaryCollection 
{
	height: 100%;

	&__title {
		// height: .em(48)[];
		font-size: .em(28)[];
		line-height: 1;
		font-weight: bold;
		padding-bottom: .em(24, 28)[];
		padding-left: .em(6)[];
	}
	&__items {
		padding-bottom: .em(32)[];
	}
	&__item {
		& + & { margin-top: 1.5em; }	
	}

	@media @tablet {
		&__items {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: repeat(auto-fit, minmax(28rem, 1fr));
			grid-gap: 1em;
		}
		&__item {
			margin-top: 0 !important;
		}
	}
	
	@media @large 
	{
		&__items {
			height: calc( 100% - .em(52)[]);
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}