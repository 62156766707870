//main: ../build.less
@import (reference) '../config';

// @viewMenu-bg: @color-primary-100;
// @viewMenu-section-bg: mix(@color-primary-100, @color-primary-200, 50%);
// @viewMenu-section-bg-hover: @color-primary-200;
// @viewMenu-section-bg-active: @color-primary-500;
// @viewMenu-submenu-bg: mix(@color-primary-100, @color-primary-200, 50%);

// @viewMenu-color: @color-white;
// @viewMenu-color-active: @color-primary-500;

// these settings are duplicated in views/app -> move to config
@menuHideSelector: ~".hide\:menu ";
@viewMenu_max_width: 25em;
@viewMenu_collapsed_width: 5rem;


.viewMenu {
	// background-color: @viewMenu-bg;
	background-color: @nav[bg];
	overflow-x: hidden;
	box-shadow: @nav[box-shadow];

	display: flex;
	flex-flow: column nowrap;
	max-height: 100vH;

	position: relative;
	z-index: @z-appMenu;

	&::before {
		content: '';
		display: block;
		position: absolute;
		z-index: @z-appMenu + 10;
		// background-color: red;
		top: 0; left: 0; right: .em(8)[];
		height: .em(10)[];
		background-image: linear-gradient(to bottom, @color-white 50%, rgba(255,255,255,0));
	}

	&__content {
		flex: 1 1 100%;
		overflow-y: auto;
		padding: @nav[@padding][vertical] @nav[@padding][horizontal];
	}
	&__user { flex: 1 1 3.5em; }


	&__section {
		// @media @fixed {
		// 	min-width: @viewMenu_max_width;
		// }

		&+& {
			#set.margin(top, 2);
		}

		// &:not(.is\:open) &Title {
		// 	background-color: @nav[@section][bg];

		// 	&:hover {
		// 		background-color: @nav[@section][@hover][bg];
		// 	}
		// }

		a, &ItemLabel {
			color: @nav[@item][color];
			text-decoration: none;
		}

		&Title {
			// position: sticky;
			position: relative;
			z-index: @z-appMenu + 20;
			top: 0;
			background-color: @nav[@section][bg];
			border-radius: @nav[@item][border-radius];
			transition: @transition[@controls][subtle];
			height: 3.5em;
			padding: 0 1em 0 1.5em;
			display: flex;
			align-items: center;
			font-weight: 600;
			cursor: pointer;

			&:hover {
				background-color: @nav[@section][@hover][bg];
			}

			.can\:toggle & {
				#icon.set(arrow_drop_down, 16, currentColor, 0, after);

				&::after {
					margin-left: auto;
				}
			}

			.can\:toggle.is\:open & {
				&::after {
					content: @icon-arrow_drop_up;
				}
			}
			.is\:disabled & {
				cursor: default;
				color: @color-text-faded;
				background-color: @nav[@section][bg] !important;
			}

			.count {
				margin-left: 1em;
			}

			i {
				// display: none;
				// visibility: hidden;
				margin-right: 0.5em;
			}

			.link {
				display: flex;
				flex-grow: 1;
				align-items: center;
			}
		}

		.is\:active &Title,
		.is\:active &Title a,
		.is\:active &Title:hover,
		.is\:active &Title:hover a {
			font-weight: 600;
			color: @nav[@section][@active][color];
			background-color: @nav[@section][@active][bg];

			&::before {
				color: @nav[@section][@active][color];
			}
		}

		&Items {
			display: none;
			visibility: hidden;
			padding-top: @nav[@item][spacing];
			padding-bottom: @nav[@item][spacing];

			.is\:open & {
				display: block;
				visibility: visible;
			}
		}

		&Item {

			border-radius: @nav[@item][border-radius];
			background-color: transparent;
			transition: @transition[@controls][subtle];

			& + & { margin-top: @nav[@item][spacing];}

			// .link:link,
			// .link:visited {
			// 	// display: block;
			// 	padding: 0.75em 1em 0.75em 1.5em;
			// 	color: @nav[@item][color];
			// 	border-radius: @nav[@item][border-radius];
			// 	text-decoration: none;
			// 	transition: @transition[@controls][subtle];
			// }

			&:hover {
				color: @nav[@item][@hover][color];
				background-color: @nav[@item][@hover][bg];
			}

			// &.is\:open {
			// 	&:hover {
			// 		background-color: transparent;
			// 	}
			// 	> a {
			// 		font-weight: 500;
			// 		color: @nav[@item][@active][color];
			// 		background-color: @nav[@item][@active][bg];
			// 	}
			// }

			[class*="icon:"]::before {
				margin-right: .5em;
			}

			.count {
				margin-left: 0.5em;
				height: 1em;
				align-self: center;
			}

			.popoverTrigger {
				height: auto;
				background-color: transparent;
				display:inline-block;
				margin-left: auto;
				width: 2em; flex-shrink: 0;
			}

			&Label {
				display: flex;
				color: @nav[@item][color];
				border-radius: @nav[@item][border-radius];
				text-decoration: none;
				transition: @transition[@controls][subtle];
				padding: 0.75em 1em 0.75em 1.5em;

				// button overrides
				.font--normal();
				text-align: initial;
				width: 100%;
				background: none;
			}

			&s--bookmarks &Label {
				padding-left: 1.2em;
			}

			.link, .link .flex {
				display: flex;
				overflow: hidden;
				flex-grow: 1;

				i { order: 0; }

				.label {
					order: 2;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.count {
					order: 1;
					min-width: 2.5em;
					margin-right: 0.75em;
					margin-left: 0;
				}
			}
			&.is\:open .count {
				background-color: mix(@count[color], @nav[@item][@active][bg], 75%);
				color: @count[bg];
			}
			&.is\:active {
				font-weight: bold;
			}

			input {
				display: inline-block;
				height: auto;
				background-color: transparent;
				padding: 0;
				border: none;
				border-bottom: 1px solid;
				color: inherit;
				border-radius: 0;
			}

		}

		// todo replace div with class name
		// &ItemLabel, &Item div {


		.addItemButton {
			// padding: 0.25em;
			justify-content: flex-start;
			align-items: center;
			padding-left: 1.6em;
			// &::before { margin-left: -1em; }
			color: @nav[@action][color];
			// background-color: @nav[@action][bg];
			&:hover {
				color: @nav[@action][@hover][color];
				background-color: @nav[@action][@hover][bg];
			}
		}

		&Item.is\:open {
			&:hover {
				background-color: transparent;
			}
		}

		&Item.is\:open > &ItemLabel, &Item.is\:open > .link {
			font-weight: 500;
			color: @nav[@item][@active][color];
			background-color: @nav[@item][@active][bg];

			a {
				color: @nav[@item][@active][color];
			}
		}

		.addItemButton.is\:disabled {
			color: @color-text-faded;
			cursor: not-allowed;
			&:hover {

				color: @nav[@action][color];
			}
		}
	}

	&__subMenu {
		background-color: @nav[@submenu][bg];
		border-left: @nav[@submenu][ruler];
		margin: @nav[@submenu][@spacing][vertical] 0 @nav[@submenu][@spacing][vertical] @nav[@submenu][@spacing][horizontal];

		&Item {
			padding: 0.667em 1.25em;
			&:hover {
				text-decoration: underline;
				// color: @nav[@item][@hover][color];
				// background-color: @nav[@item][@hover][bg];
			}

			&.is\:open {
				font-weight: bold;
			}
		}

		// &--bookmarkList &Item {
		// 	margin-left: 0.25em;
		// }
	}

	@media @fixed {

		// Collapsed Menu Styles
		@{menuHideSelector} {
			overflow: hidden;
		}

		@{menuHideSelector} &__section {
			overflow: hidden;

			&Title {
				width: @viewMenu_collapsed_width;
				padding: 0;
				justify-content: center;

				i {
					display: block;
					visibility: visible;
				}

				.label {
					display: none;
				}

				&::after {
					display: none;
				}

				.count {
					font-size: 0.8rem;
					// margin-left: 0.25em;
				}
			}

			&Item {

				a:link,
				a:visited {
					padding: 0.75em 1em 0.75em 1em;
				}

				.label {
					display: none;
				}
			}
		}

		@{menuHideSelector} &__subMenu {
			margin-left: 0.5em;
			counter-reset: submenu;

			&Item {

				a:link,
				a:visited {
					padding-left: 0.75em;
				}

				a::before {
					content: counter(submenu, decimal-leading-zero);
					counter-increment: submenu;
				}
			}
		}

		&__section {

			&Item {
				.popoverTrigger {
					display:none;
				}
				.popoverTrigger.is\:active {
					display: inline-block;
				}
				&:hover {
					.popoverTrigger {
						display: inline-block;
					}
				}
			}
		}
	}

	&__user {
		#set.text( 14, 20 );
		background-color: @nav[@user][bg];
		#set.padding( 12, 12, 12, 6 );

		display: grid;
		grid-template-columns: 4rem 1fr 3rem;
		grid-template-rows: 1fr 1fr;
		grid-template-areas: "icon user logout" "icon actions logout";

		a {
			text-decoration: none;
			color: @nav[@user][@actions][color];
			display: flex;
			align-items: center;

			&:hover {
				color: @nav[@user][@actions][@hover][color];
				> span { text-decoration: underline; }
			}
		}

		&::before {
			grid-area: icon;
			#set.text( 40,40 );
			color: @nav[@user][icon];
			place-self: start center;
		}
		position: relative;
		&::after {
			content: '';
			background-image: linear-gradient( to top, @nav[@user][shadow-color] 0%, transparent 100%);
			position: absolute;
			left: 0; right: 0;
			top: -3em;
			height: 3em;
			pointer-events: none;
		}

		&Name {
			grid-area: user;
			font-weight: 500;
			color: @nav[@user][color];
			#set.text( 15, 22 );
		}
		&Actions {
			grid-area: actions;

			a {
				display: inline;
				&::before { margin-right: 0.25em;}
			}
		}
		&Logout {
			grid-area: logout;
			place-self: center center;
			.button {
				display: flex;
				align-items: center;
				padding: 0.25em;
				border-radius: @ui-rounding-controls;
				// border-radius: 0.15em;
				background-color: @header[@button][bg];
				transition: @transition[@controls][subtle];

				&::before {
					#set.text( 18, 18 );
					color: @color-primary-400;
				}
				&:hover {
					background-color: @header[@button][@hover][bg];
					color: @header[@button][@hover][color];

					&::before {
						color: @color-white;
					}
				}
			}
			// a > span { display: none; }
		}
	}

	&__content .spinner {
		top: 50%;
    position: relative;
    margin-top: -4rem;
	}
}

.count {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	min-width: 2em;
	min-height: 2em;
	background-color: @nav[@section][@counter][color];
	background-color: @nav[@section][@counter][bg];
	font-weight: bold;
	color: @color-white;
	border-radius: 10%;
	#set.text(12, 20);
}
