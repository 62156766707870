#set(){

	.border (
		@side,
		@pixel,
		@params: optional
	) {

		& when ( iskeyword(@side) )
		  and  ( isnumber(@pixel) )
		{
			border-@{side}: unit( (@pixel / @__REM_SCALING), rem ) @params;
		}

		& when ( isnumber(@side) )
		{
			border: unit( (@side / @__REM_SCALING), rem ) @pixel;
		}
	}
}
