// main: ../build.less
@import (reference) "../config";
// @import (reference) "../_shared/components/button-v2.less";

// needed to access color variables?
// #colorscheme.default();
#ui.style--rounded();
#ui.theme--light();

#button-v2.size( @buttons[@size][@default] );

@__button-selector: @buttons[@selectors][base];


button {
	&.has\:iconOnly {
		display: flex;	
		justify-content: center;
		align-items: center;
	}
}

// button, // dont want to keep the generic selector here, 
// TODO: <Button> component needs default class .button if not used for things that shouldnt look like a button
@{__button-selector} 
{
	font-weight: 500;

	// Default color
	color: @buttons[color];
	background: @buttons[bg];
	// outline-offset: .em(100)[];
	&::before { color: @buttons[icon]; }
	&:hover {
		color: @buttons[@hover][color];
		background: @buttons[@hover][bg];
		&::before { color: @buttons[@hover][icon]; }
	}
	&:active {
		color: @buttons[@active][color];
		background: @buttons[@active][bg];
		&::before { color: @buttons[@active][icon]; }
	}
	&:focus-visible {
		outline: @buttons[@focus][outline];
		// outline-offset: .em(1)[];
	}

	// Primary Variant
	&\:primary {
		color: @buttons[@primary][color];
		background: @buttons[@primary][bg];
		&::before { color: @buttons[@primary][icon]; }
		&:hover {
			color: @buttons[@primary][@hover][color];
			background: @buttons[@primary][@hover][bg];
			&::before { color: @buttons[@primary][@hover][icon]; }
		}
		&:active {
			color: @buttons[@primary][@active][color];
			background: @buttons[@primary][@active][bg];
			&::before { color: @buttons[@primary][@active][icon]; }
		}
		&:focus-visible {
			outline: @buttons[@primary][@focus][outline];
			outline-offset: .em(1)[];
		}
		&.is\:disabled {
			background-color: fade(@buttons[@primary][bg], 50%);
		}
	}

	// Outlined default
	&\:outline {
		color: @buttons[bg];
		background: transparent;
		border: @buttons[@outline][border-width] solid currentColor;
		&:hover{
			color: @buttons[@hover][bg];
			background: transparent;
		}
		&:active{
			color: @buttons[@active][bg];
			background: transparent;
		}
		&:focus-visible {
			outline: none;
			background-color: @buttons[@active][bg];
			color: @buttons[@primary][color];
		}
	}
	// Outlined primary
	&\:primary&\:outline {
		color: @buttons[@primary][bg];
		&:hover{
			background-color: transparent;
		}
		&:active{
			background-color: transparent;
		}
		&:focus-visible {
			// background-color: transparent;
			background-color: fade(@buttons[@hover][bg], 10%);
		}
		// &:active{ color: @buttons[@primary][@active][bg]; }
		&.is\:disabled {
			background-color: transparent;
			color: fade(@buttons[@primary][bg], 50%);
		}
	}
	&\:danger {
		color: #fff;
		background: @color-text-error;
		&:hover, &:active {
			background: saturate(@color-text-error, 15%)
		}
	}
	
	&\:success {
		color: #fff;
		background: @color-text-success;
		&:hover, &:active {
			// background: saturate(@color-text-success, 15%)
			background: @color-text-success;
		}
	}

	// Stripped variant
	&\:naked {
		background: transparent !important;
		border: none !important;
	}

	// TODO see config button sizes
	&\:small {
		#set.rem( height, 28 );
		#set.padding(14, 16, 14, 14);
		#set.text(12, 16);
		// #set.rem( border-radius, 8 ); 
		border-radius: @ui-rounding-input;
		&::before {
			#set.text(14, 14);
		}
		&:focus-visible {
			outline: @buttons[@focus][outline];
			outline-offset: .em(2)[];
		}
		&.has\:iconOnly {
			#set.rem(width, 28);
		}
	}

	&.is\:loading {
		padding-left: 1.2em;
		cursor: default;
	}

	&.is\:disabled {
		pointer-events: none;
		&::before {
			opacity: 0.6;
		}
	}

	.spinner {
		#set.margin(0, 8, 0, 0);
	}


}

// #button-v2.variant({
// 	selector: ~"\:primary";
// 	border-width: 0;
// 	border-radius: @buttons[@default][@size][border-radius];

// 	bg: @color-primary-500;
// 	color: @color-white;
// 	icon: @color-primary-900;
// 	border: false;

// 	@hover: {
// 		bg: @color-primary-400;
// 		color: @color-white;
// 		icon: @color-primary-900;
// 		border: false;
// 	}
// 	@active: {
// 		bg: @color-primary-400;
// 		color: @color-white;
// 		icon: @color-primary-900;
// 		border: false;
// 	}
// });

// #button.variant( \:primary,
// 	@color-primary, @color-primary-faded, @color-primary,
// 	#14161E, #14161E, #14161E
// );
// #button.variant( \:secondary,
// 	@color-secondary, @color-secondary-muted, @color-secondary
// 	#E6EAF0, #E6EAF0, #E6EAF0
// );
// #button.variant( \:outline,
// 	transparent, inherit, transparent,
// 	@color-text-faded, @color-text-faded, @color-text-faded,
// 	currentColor,currentColor,currentColor,
// 	1, @color-text-faded, @color-text-faded, @color-text-faded, 3
// );

// REF
// #button.size (
// 	@selectorExtension,
// 	@font-size,
// 	@icon-size,
// 	@height,
// 	@padding-left,
// 	@padding-right,
// 	@border-radius,
// )


// #button.size( \:small, 
// 	(@button-font-size * 0.85),
// 	(@button-font-size * 0.85),
// 	(@button-height * 0.75),
// 	(@button-padding-horizontal * 0.5),
// 	(@button-padding-horizontal * 0.5)
// );
// #button.size( \:micro, 
// 	(@button-font-size * 0.8),
// 	(@button-font-size * 0.8),
// 	(@button-height * 0.6),
// 	(@button-padding-horizontal * 0.333),
// 	(@button-padding-horizontal * 0.333)
// );

// DEPRECATED STYLES
// TODO: proof new component & clean up
// --------------------------------------------------------------
// @buttonSelectorName: ~"button";
// @buttonClassSelector: ~".@{buttonSelectorName}";

// @button-background-color: #444;
// @button-background-hover: #333;
// @button-color: #fff;
// @button-border: none;
// @button-border-radius: 3;
// @button-padding-vertical: 8;
// @button-padding-horizontal: 24;
// @button-icon-size: 20;

// .buttonLike {
// 	display: inline-flex;
// 	flex-flow: row nowrap;
// 	justify-content: center;
// 	align-items: center;
// 	position: relative;
// 	#set.padding( @button-padding-vertical, @button-padding-horizontal );
// 	#set.margin( right, 2 );

// 	#set.rem( border-radius, @button-border-radius );
// 	border: @button-border;
// 	background: @button-background-color;
// 	color: @button-color;
// 	transition: background .15s ease-out;

// 	font-family: @font-family;
// 	.font--normal;

// 	&:hover { background-color: @button-background-hover; }
// }

// #variant() {
// 	.button( @handle, @bg, @color, @hover )
// 	{
// 		.button\:@{handle}
// 		{
// 			background: @bg;
// 			color: @color;
// 			&:hover { background-color: @hover; }
// 		}
// 	}
// }

// // buttons have no default ui
// button {
// 	padding: 0;
// 	margin: 0;
// 	background: none;
// 	border: none;
// 	cursor: pointer;
// 	// transform: translateY( 0 ) translateZ(0);
// 	transform: translate3d(0, 0, 0);
// 	transition: transform .1s ease-out;
// 	backface-visibility: hidden;

// 	&:focus { outline: 0; }
// 	&:active {
// 		outline: 0;
// 		// transform: translateY( unit( 1 /  @__REM_SCALING, rem) ) translateZ(0);
// 		transform:  translate3d(0, unit( 1 /  @__REM_SCALING, rem), 0);
// 	}
// }
// // real "buttons" need a styling class
// @{buttonClassSelector},
// [class*="@{buttonSelectorName}:"]
// {
// 	&:extend( .buttonLike all );
// 	a& {
// 		// color: inherit;
// 		font-weight: 500;
// 		text-decoration: none;
// 	}
// 	&[class*=":secondary"] { background: #999; }

// 	&:not(.has\:iconOnly) i,
// 	&:before {
// 		#set.margin( right, 6 );
// 	}
// 	&[class*="icon:"] {
// 		#set.padding( left, @button-padding-horizontal - 8 );
// 	}
// 	&.has\:iconOnly {
// 		#set.padding( @button-padding-vertical, @button-padding-vertical * 1.25 );
// 		#set.rem( width, (@button-padding-vertical * 2.5) + @button-icon-size );
// 		&:before { margin: 0; }
// 	}
// }

// #variant.button( primary, #A5CC2E, #14161E, #B6E032 );
// #variant.button( secondary, #006A58, #E6EAF0, #005B47 );
// #variant.button( naked, transparent, inherit, transparent );


