 // main: ../build.less
 @import (reference) '../config';

 .viewSide {
	&__header {
		padding: 0 0.25em 1em;
		@media @tablet { 
			margin-top: 2em; 
			padding: 0 1em 1.25em;
		}
	}
	&__title {
		font-size: .em(24)[];
		line-height: 1;
		font-weight: 700;

		@media @tablet {
			font-size: .em(28)[];
		}
		@media @desktop {
			font-size: .em(32)[];
		}
	}
 }