// main: ../build.less
@import (reference) '../config';


.filterTagList {
	
	align-items: baseline;
	// font-size: .em(@tag[size--normal])[];
	font-size: .em(@tag[size--small])[];

	padding: .em(12, @tag[size--small])[] 0 0em;
	flex-shrink: 0;
	color: white;
	@filterTag-border-radius: 3;

	gap: .em(8, @tag[size--small])[];

	display: flex;
	flex-flow: row nowrap;
	overflow-x: auto;
	overflow-y: visible;

	@media @tablet {
		// margin-left: .em(60, @tag[size--small])[];
		flex-flow: row wrap;
		overflow: visible;
	}
	
	&__label {
		font-size: .em(15)[];
		color: @color-text;
		font-weight: bold;
	}
	
}

.filterTag {
	cursor: pointer;
	height: @tag[height];
	line-height: 1;
	display: flex;
	align-items: stretch;
	white-space: nowrap;
	
	> * { 
		display: flex;
		align-items: center;
	}

	&__field {
		font-weight: 500;
		text-transform: capitalize;
		background: @tag[@marker][bg];
		color: @tag[@marker][color];
		
		padding-left: .em(10)[];
		padding-right: .em(8)[];
		border-top-left-radius: @tag[border-radius];
		border-bottom-left-radius: @tag[border-radius];
	}

	&__value {
		background-color: @tag[bg];
		color: @tag[color];

		padding-left: .em(8)[];
		padding-right: .em(8)[];
		border-top-right-radius: @tag[border-radius];
		border-bottom-right-radius: @tag[border-radius];

		#icon.set( clear, 14, @tag[control], 5, after );
	}
}