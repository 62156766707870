
.textInput {
	display: inline-flex;
	align-items: center;
	width: 100%;
	overflow: hidden;
	position: relative;
	background-color: @color-white;
	// background-color: fadeout(@color-white, 50%);
	// background-color: red;

	.inputLike;
	padding-left: 0;
	padding-right: 0;

	

	&.icon::before {
		#set.margin(right, 6);
	}

	.input {
		width: 100%;
		border: 0;
		padding-top: 0;
		padding-bottom: 0;
		background-color: transparent !important;

		&:focus {
			outline: none;
		}
	}

	&__visibilityToggle {
		position: absolute;
		background: transparent;
		height: @input[height];
		color: @color[primary-700];
		right: 0;
		height: 100%;
		#set.padding(0, 8);

		&:focus {
			background-color: @color[primary-100];
			outline: none;
		}
	}
	
	&:focus-within &:has(:focus-visible) {
		outline: 2px solid @color-black-200;
		outline-offset: 1px;
	}

	.input__icon {

	}

}