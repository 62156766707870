// main: ../build.less
@import (reference) '../config';

.summaryWidget {
	display: flex;
	justify-content: center;
	flex-flow: row wrap;

	&__input {
		display: flex;
		width: 100%;
		#set.margin(top, 8);
		#set.padding(0, 8);

		.textInput {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			width: 100%;
			#set.text(13, 16);

			&:focus {
				outline: none;
			}
		}

		.button {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			#set.rem(width, 42);
			height: auto;
			&:focus {
				outline: none;
				background-color: @color[primary-500];
			}
			.spinner {
				#set.margin(right, 2);
			}
		}

	}

	&__presets {
		display: flex;
		width: 100%;
		max-width: 90%;
		flex-flow: row wrap;
		justify-content: center;
		column-gap: 0.5em;
		#set.margin(top, 16);
	}

	&__preset {
		white-space: nowrap;
		background-color: @color[primary-100];
		color: @color[primary-800];
		#set.padding(4, 12);
		border-radius:  @ui-rounding-contentbox;
		#set.margin(6, 0);
		transition: background-color 0.2s ease-in-out;
		height: auto;
		#set.text(13, 14);

		&:hover {
			background-color: @color[primary-200];
			color: @color[primary-800];
		}

	}

	&__output {
		position: relative;
		#set.text(14, 21);
		// #set.margin(bottom, 0);
		width: 100%;

		hr {
			margin: 1rem auto;
			border-color: @color[primary-50];
			background-color: @color[primary-50];
			height: 1px;
			border-width: 0;
			width: 80%;
		}

		// when writing a list
		&.is\:writing .markdown > ul:last-child li:last-child {
			// background-color: red;
			&::after {
				content: "|";
				animation: blinking-cursor 1s linear infinite;
			}
		}

		// when writing anything else
		&.is\:writing .markdown > *:not(ul):last-child {
			// background-color: red;
			&::after {
				content: "|";
				animation: blinking-cursor 1s linear infinite;
			}
		}

	}

	&__resultActions {
		#set.margin(top, 18);
		display: flex;
		justify-content: center;
	}
	
}

@keyframes blinking-cursor {
	0% {
		opacity: 0;
	}
	50% {
		opacity: .5;
	}
	100% {
		opacity: 1;
	}
}

