// main: ../build.less
@import (reference) '../config';


.count {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: @count[font-size];
	min-width: @count[size];
	min-height: @count[size];
	border-radius: @count[border-radius];
	background-color: @count[bg];
	color: @count[color];
	font-weight: bold;
}