// main: ../index.less
@import (reference) "../config.less";

* { box-sizing: border-box; }

html,
body,
#__next {
	width: 100%;
	max-width: 100%;
	height: 100%;
	min-height: 100%;
}

html {
	// Set REM to target size, defaults 12
	font-size: percentage(((@__REM_SCALING * @__GLOBAL_ZOOM_FACTOR) / 16));
}
body {
	.font--normal;
}
