// out: false
#em() {
	.text (
		@font-size,
		@absolute-line-height,
		@base: @__REM_SCALING
	) {
		font-size: .em(@font-size, @base)[];
		line-height: (@absolute-line-height / @font-size);
	}
}
