 // main: ../build.less
@import (reference) '../config';

.view {
	&--account {
		// display: flex;
		// flex-flow: column;
		// min-height: 100vh;
	}

	&--account &Body {
		@media @fluid {
			grid-template-rows: 0 1fr;
		}
		// @media @tablet {}
		@media @fixed {
			grid-template-columns: 25em 1fr;
			grid-template-areas: "menu main";
		}
		// @media @desktop {}
	}

	&--account &Main {
		width: 100%;
	}
}

.account {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	gap: 4rem;
	#set.padding(28, 22);

	.input {
		width: 100%;
	}

	&__title {
		#set.text(22, 22);
	}

	&__section {
		max-width: 36rem;
		flex: 1 1 30rem;
	}

	// &__section + &__section {
	// 	#set.margin(top, 42);
	// }

	&__sectionTitle {
		#set.text(19, 19);
	}

	&__form {
		display: flex;
		flex-flow: column;
	}

	&__formSubmit {
		#set.margin(top, 16);
		align-self: flex-end;
		@media @fixed {
			#set.margin(top, 24);
		}
	}
	
	@media @fixed {
		#set.padding(28, 32);
	}
	
	@media @desktop {
		#set.padding(48, 32);
	}

}