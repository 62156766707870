// main: ../build.less
@import (reference) "../config";
.floatyBox {
	display: flex;
	flex-flow: column;
	// border-radius: 1rem;
	backdrop-filter: blur(5px);
	background-color: fadeout(@color-white, 66%);
	box-shadow: @widget[box-shadow];
	border-radius: @widget[border-radius];
	transition-property: box-shadow, background-color;
	transition-duration: 0.2s;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	box-shadow: 0 0.5em 2em -0.1em fadeout(@color[black-900], 95%);
	#set.padding(32, 32, 32, 38);

	&__title {
		#set.text(20, 18);
		font-weight: 600;
	}

	&__text {
		#set.text(14, 16);
		#set.margin(10, 0, 10, 0);
		font-weight: 500;
	}

	&__action {
		align-self: flex-end;
		background: fadeout(@color-primary, 90%);
		border: 1px solid transparent;
		#set.margin(top, 16);
		&:hover {
			border-color: transparent;
			// border-color: fadeout(@color-secondary, 95%);
			background: fadeout(@color-primary, 85%);
			color: @color[primary-600] !important;
		}
		&:focus {
			// border-color: transparent;
			background: fadeout(@color-primary, 70%);
			color: @color[primary-600] !important;
			outline: 1px solid @color-primary;
			outline-offset: 0.1em;
			&::before {
				color: @color[primary-600] !important;
			}
		}
	}

	
	&:hover, &:focus-within {
		box-shadow: 0 0.5em 2em -0.1em fadeout(@color[black-900], 90%);
		background-color: fadeout(@color-white, 60%);
	}

	@media @mobile {
		box-shadow: none !important;
		backdrop-filter: none;
		background: none;
		transition: none;
		#set.padding(24);
		margin: 0;
	}

	// @media @mobile {
	// 	#set.padding(24);
	// 	margin: 0;
	// }
}