.pill {
	border-radius: 1.5em;
	padding: .em(6) [] .em(16) [];
	font-size: .em(16) [];
	font-weight: 500;
	color: @color-text;
	background-color: white;
	height: auto;

	&.is\:disabled {
		color: fade(@color-text, 40%);
	}

	&__icon {
		margin-right: .5em;
	}

	&__icon::before {
		font-size: .em(24) [];
	}

	&__body {
		display: flex;
		flex-flow: column;
	}

	&__title {
		font-size: .em(14) [];
		font-weight: bold;
	}

	&__label {
		font-size: .em(12) [];
		white-space: normal;
	}
}
