// main: ../build.less
@import (reference) '../config';

.extendedSearch {

	// positioning is handled in views/app.less

	background-color: @search[bg];

	&__header,
	&__filters,
	&__actions {
		padding: 1em;
	}

	&__title {
		#em.text(21, 26);
		// font-size: .em(21)[];
		// line-height: 1.25;
		font-weight: bold;
	}
	&__desc {
		margin-top: .em(6)[];
		color: @search[text-faded];
	}

	@media @fluid {
		&__header, 
		&__filters {
			margin-left: auto;
			margin-right: auto;
			max-width: .em(640)[];
		}
	}
	@media @tablet {
		&__header { 
			max-width: 100%;
			padding: 1em .em(24)[];
		}
		&__filters { 
			max-width: 100%;
			display: flex;
			gap: .em(48)[];
			padding: 1em .em(24)[];
			> * { flex: 1 1 100%; }
		}
	}

	@media @fixed {
		
		padding: 0.5em;
		display: grid;
		grid-template-columns: 1fr 16em;
		grid-template-rows: 2fr 1.5fr;
		grid-template-areas: 
			"filters header"
			"filters actions";
		
		&__header { 
			grid-area: header; 
			place-self: center; 
			text-align: center;
		}
		&__filters { grid-area: filters; }
		&__actions { grid-area: actions; }

		border: @header[@searchbox][border-width] solid @header[@searchbox][border];
		.show\:extendedSearch & {
			border-color: @header[@searchbox][@active][border]
		}
		border-top-width: 0;
		border-bottom-left-radius: @header[@searchbox][border-radius];
		border-bottom-right-radius: @header[@searchbox][border-radius];
	}
	@media @desktop {
		grid-template-columns: 1fr 20em;
	}

	&__filters 
	{
		padding-bottom: 8em;

		&Title { 
			#em.text( 18, 24 );
			font-weight: 600;
			margin-top: 1em;
		}

		@media @fixed {
			padding-bottom: 1.5em;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-auto-flow: column dense;
			grid-gap: 0 1.5em;
			
			// uncomment to enable intermediary step with no titles & balanced 2col input layout
			// grid-template-rows: repeat(5, 1fr);
			// &Title { display: none; visibility: hidden; }
			// &Group { display: contents; }
		}
		
		@media @large {
			grid-template-columns: 1fr 2fr;
			grid-template-rows: auto;
			grid-auto-flow: row;
			
			&Title { 
				display: block; 
				visibility: visible; 
				margin-top: 0; 
			}
			&Group { display: block; }
			&Group--filters {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-template-rows: repeat(3, min-content);
				grid-gap: 0 1.5em;
			}
			&Group--filters &Title { grid-column: 1 / span 2;}
		}
	}

	&__error {
		background-color: @color-bg-error;
		border: .em(1)[] solid @color-text-error;
		padding: .em(8)[] .em(16)[];
		color: @color-text-error;
		text-align: left;
		border-radius: @ui-rounding-medium;

		&Title {
			font-weight: 600;
		}
		&Messages {
			list-style: outside;
			margin-top: .em(8)[];
			margin-left: .em(16)[];
		}
	}


	&__actions {
		@media @fluid {
			display: flex;
			justify-content: center;
			// flex-flow: row-reverse;
			background-color: @search[bg];
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: @z-foreground + 100;
			box-shadow: 0 0 1.5em rgba(0, 0, 0, .35);
		}
		@media @fixed {
			display: flex;
			flex-flow: column;
			align-items: center;
			padding-bottom: 1.75em;
			margin-top: auto;

			> .button { 
				width: 100%;
				margin: .em(5)[] 0 0 0 !important;
			}
		}

		// @media @fixed {
		// 	flex-flow: row wrap;
		// 	align-items: center;
		// 	justify-content: center;

		// 	button {
		// 		margin-bottom: 0;
		// 	}
		// }

		// @media @desktop {
		// 	display: flex;
		// 	flex-flow: column nowrap;
		// 	align-items: center;
		// 	justify-content: center;
		// }
	}

	// &__applyBtn {
	// 	#set.margin(bottom, 8);
	// 	color: @color-white;
		

	// 	&:hover {
	// 		color: @color-white;
	// 		background-color: mix(@color-primary, @color-bg-faded, 90%);
	// 		// background-color: @color-secondary;
	// 	}

	// 	&.is\:disabled {
	// 		cursor: default;
	// 		color: @color-text-faded;
	// 		background-color: @color-primary;
	// 		&:hover {
	// 			color: @color-text-faded;
	// 			// background-color: @color-primary-faded;
	// 		}
	// 		// background-color: @color-primary-faded;

	// 	}

	// 	&.is\:focus,
	// 	&:focus {
	// 		outline-offset: 0.1em;
	// 		outline: 1px solid @color-primary;
	// 	}
	// }

	// &__resetBtn {
	// 	color: @color-primary-700;
	// 	border-color: currentColor;
	// 	&.is\:disabled {
	// 		cursor: default;
	// 		opacity: 0.5;
	// 	}

	// 	&.is\:focus,
	// 	&:focus {
	// 		outline-offset: 0.1em;
	// 		outline: 1px solid @color-primary;
	// 	}
	// }

	.field {
		padding: 0.5em;
		@media @fixed { padding: 0.5em 0; }

		// width: 100%;
		&__label {
			font-weight: 500;
			color: @search[text-faded];
			padding-top: 0.75em;

			@media @fixed { 
				// color: @color-primary-700;
				padding-top: 0;
			}
		}

		&__input {
			// #set.rem(width, 256);
			width: 100%;
			// background-color: @search[@input][bg];
			// border-color: @search[@input][border];
			color: inherit;

			&::placeholder,
			&select:invalid {
				opacity: 1;
				// color: @search[@input][placeholder];
			}

			&.is\:focus,
			&:focus,
			&:focus-within {
				border-color: @input[@focus][border];
				outline: none;
				// outline: 1px solid @color-primary;
				// outline-offset: 0.1em;
			}
		}
	}
}