// main: ../build.less
@import (reference) '../config';

// general form input defaults
// @input-line-height: 26;
// @input-padding-vertical: 8;
// @input-padding-horizontal: 12;
// @input-default-height: @input-line-height + (@input-padding-vertical * 2);

// @input-border-radius: 3;
// @input-border-width: 1;
// @input-border-color: #999;
// @input-border-focus-color: #000;
// @input-background-color: #fff;
// @input-placeholder-color: @color-text-faded;

// @input-icon-size: 18;
// @input-icon-color: @input-border-color; // #999

// // select-specific
// @select-border-radius: @input[border-radius];
// @select-border-width: @input[border-width];

.inputLike {
	// base styling of an input box
	// components that look like an input but have markup structure extend their root onto this

	display: inline-flex;
	position: relative;
	font-size: @input[font-size];
	line-height: 1;
	padding: @input[@padding][vertical] @input[@padding][horizontal];
	height: @input[height];
	border: @input[border-width] solid @input[border];
	border-radius: @input[border-radius];

	&.input\:small {
		#set.rem( height, 28 );
		padding: (@input[@padding][vertical] / 2) (@input[@padding][horizontal] / 2);
		#set.text(12, 16);
		#set.rem( border-radius, 8 ); 
	}
}

// how an actual input looks like
input,
select {
	font-family: @font-family;
	&:extend(.inputLike all);
	
	transition: @transition[@controls][smooth];

	&:focus {
		border-color: @input[@focus][border];
	}
}

// behaviour custom to real inputs
input {
	&::placeholder {
		color: @input[placeholder];
	}
}

textarea {
	width: 100%;
	font-family: @__FONT_NAME;
	padding: @input[@padding][vertical] @input[@padding][horizontal];
}

.form__field {
	display: flex;
	flex-flow: column wrap;
	align-items: stretch;
}

.form__errors {
	// errors that are not specific to a field go here
	color: @color-text-error;
	#set.text(14, 18);
	#set.rem(min-height, 24);
	display: flex;
	align-items: center;
	align-self: flex-start;
}

.field {
	&__label {
		display: block;
		#set.padding(20, 0, 4);
		.font--label;
		font-weight: 500;
		color: #444;
	}

	&__errors {
		color: @color-text-error;
		#set.text(13, 18);
		#set.rem(min-height, 24);
		display: flex;
		align-items: center;
	}

	&__input--checkbox,
	&__input--radio {
		display: flex;

		input {
			#set.rem(height, 16);
			#set.margin(right, 8);
		}
	}

	&.has\:pendingChanges {
		input {
			background-color: blue;
			border-color: lightblue;
		}
	}

	&.has\:error {

		input,
		.select__control {
			background-color: @color-bg-error;
			border-color: @color-text-error;
		}
	}
}

.field .file.has\:error {

	input,
	.select__control {
		background-color: @color-bg-error;
		border-color: @color-text-error;
	}
}

.field.has\:error {
	position: relative;

	.field__errors {
		position: absolute;
		right: 0;
		#set.rem(bottom, 42);
	}

	label {
		color: @color-text-error;
	}
}

// Drafting

input[type='time'] {
	#set.padding(8, 10);

	// &:before {
	// 	font-family: '@{__ICON_FONT_NAME}';
	// 	content: @icon-time;
	// 	#set.text( @input-icon-size, @input-line-height );
	// 	#set.margin( right, 8 ) !important;
	// 	color: @input-icon-color;
	// }
	&::-webkit-datetime-edit-hour-field,
	&::-webkit-datetime-edit-minute-field,
	&::-webkit-datetime-edit-ampm-field {
		#set.padding(0, 3);
	}

	&::-webkit-clear-button {
		// font-family: '@{__ICON_FONT_NAME}';
		// content: @icon-cancel;
		display: none;
	}

	&::-webkit-inner-spin-button {
		display: none;
	}
}

input[type='number'] {
	border-radius: @ui-rounding-input;
}