#set(){

	.rem (
		@property,
		@pixel
	) {
		@{property}: unit( (@pixel / @__REM_SCALING), rem );
	}

	.rems (
		@property,
		@v1,
		@v2: none,
		@v3: none
	) {
		@{property}: unit( (@v1 / @__REM_SCALING), rem );

		& when ( isnumber(@v2) ) {
			@{property}+_: unit( (@v2 / @__REM_SCALING), rem );
		}
		& when ( isnumber(@v3) ) {
			@{property}+_: unit( (@v2 / @__REM_SCALING), rem );
		}
	}
}
