// main: ../build.less
@import (reference) '../config';

.contextMenu {
	// z-index: @z-modal;
	// position: absolute;
	#set.padding(10, 16, 16, 16);
	background-color: @menu[bg];
	border-radius: @menu[border-radius];
	box-shadow: @menu[box-shadow];
	max-width: 22rem;

	// .toggle-visibility( ~'.is\:open' );

	&__title {
		#set.text( 14, 24 );
		padding: .em(8)[] .em(10)[];
		margin-bottom: .em(10)[];
		font-weight: bold;
		color: @menu[@title][color];
		border-bottom: 1px solid @menu[@title][border];
	}

	&__item {
		color: @menu[@item][color];
		border-radius: @menu[@item][border-radius];
		background: none;
		height: 100%;
		width: 100%;

		margin: 0;
		display: block;
		text-align: left;
		#set.text( 16, 24 );
		#set.padding(10, 16, 10, 12);
		&::before,
		b {
			margin-right: 0.5em;
			color: @menu[@item][icon];
		}
		


		&::before {
			// sorry, not sure how to fix vertical icon alignment properly
			top: 2px;
			position: relative;
		}

		&:focus-visible {
			background-color: @menu[@item][@focus][bg];
			color: @menu[@item][@focus][color];
			outline: @menu[@item][@focus][outline];
			&::before,
			b {
				color: @menu[@item][@focus][icon];
			}
		}

		&:hover {
			background-color: @menu[@item][@hover][bg];
			color: @menu[@item][@hover][color];
			.betaLabel {
				color: @menu[@item][@hover][color];
			}
			&::before,
			b {
				color: @menu[@item][@hover][icon];
			}
		}


	}

	.bookmarkListSelection li:not(:last-child) {
		margin-bottom: .33em;
	}

	.checkbox {
		display:block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&--condensed {
		#set.rem(max-width, 165);
		border-radius: @ui-rounding-controls;
		padding: 0;
		#set.margin(0, 0, 0, 0);
		height: auto;
		overflow: hidden;
	}

	&--condensed &__item {
		#set.padding(10, 20, 12, 12);
		border-radius: 0;
		#set.text(14, 16);
		// &:focus {
		// 	// background-color: @menu[@item][@focus][bg];
		// 	background-color: fade(@menu[@item][@hover][bg], 10%);
		// 	color: @menu[@item][@focus][color];
		// 	outline: @menu[@item][@focus][outline];
		// 	outline: none;
		// }
		&:focus-visible {
			// background-color: fade(@menu[@item][@hover][bg], 5%);
			// color: @menu[@item][@focus][color];
			// outline: @menu[@item][@focus][outline];
			outline: none;
		}

		&:hover {
			background-color: @menu[@item][@hover][bg];
			color: @menu[@item][@hover][color];
		}
	}

}
