// main: ../build.less
@import (reference) "../config";

@columnSelectorName: ~"columns";
@column-gutter: 24;

@media @tablet
{

	[class*="columns:"] {
		display: grid;
		grid-template-rows: 1fr;
		#set.rem( column-gap, @column-gutter );
	}
	#__generate.columns.regular( 12, @columnSelectorName );
	#__generate.columns.fractions( 12, @columnSelectorName );
	#__generate.columns.sizing( 12 );
}



#__generate () {
.columns () {

	.regular (
		@cols: 12,
		@baseClass: ~"columns",
		@i: 2
	) when ( @i <= @cols )
	{
		.@{baseClass}\:@{i} { grid-template-columns: repeat( @i, 1fr ); }

		#__generate.columns.regular( @cols, @baseClass, (@i + 1) );
	}

	.fractions (
		@cols: 12,
		@baseClass: ~"columns",
		@step: 1,
		@i: 1
	) when ( @i < @cols )
	{
		@_fr:(@cols - (@i * @step));

		.@{baseClass}\:@{i}\+@{_fr} { grid-template-columns: unit( @i, fr) unit( @_fr, fr ); }

		#__generate.columns.fractions( @cols, @baseClass, @step, (@i + 1) );

	}

	.sizing (
		@cols: 12,
		@baseClass: ~"col",
		@i: 1
	) when ( @i <= @cols )
	{
		.@{baseClass}\:@{i} { grid-column: span @i; }

		#__generate.columns.sizing( @cols, @baseClass, (@i + 1) );
	}
}}
