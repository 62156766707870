// out: _test/switch.test.css
@import (reference) '../config';

// @switchSelector: .switch;
@switchSelector: e(@switch[@__SELECTOR]);

//@switch__checkboxInput: @checkboxInput;

@{switchSelector}
{
  // -------------------------------------------------------
  // COLOR SLOT MAPPING & OVERRIDES

  --border: @switch[@default][@off][border];
  --background: @switch[@default][@off][background];
  --thumb-background: @switch[@default][@off][thumb-background];
  --box-shadow: @switch[@default][inset-box-shadow];
  --thumb-border: @switch[@default][@off][thumb-border];
  --thumb-box-shadow: @switch[@default][@thumb][box-shadow];
  --icon: @switch[@default][@off][icon-color];

  //-- STATE: checked
  [data-state="checked"] {
    --background: @switch[@default][@on][background];
    --border: @switch[@default][@on][border];
    //--thumb-background: @switch[@default][@on][thumb-background];
    --thumb-border: @switch[@default][@on][thumb-border];
    --icon: @switch[@default][@on][icon-color];
  }

  &--labeled {
    //--background: @switch[@labeled][@off][background];
    --color: @switch[@labeled][@off][color];
    --border: @switch[@labeled][@off][border];
    --box-shadow: @switch[@labeled][inset-box-shadow];
    --thumb-border: @switch[@labeled][@off][thumb-border];
    //--thumb-background: @switch[@labeled][@off][thumb-background];
    --thumb-box-shadow: @switch[@labeled][@thumb][box-shadow];
    --icon: @switch[@labeled][@off][icon-color];

    //-- STATE: checked
    [data-state="checked"] {
      //--background: @switch[@labeled][@on][background];
      --color: @switch[@labeled][@on][color];
      --border: @switch[@labeled][@on][border];
      --thumb-border: @switch[@labeled][@on][thumb-border];
      //--thumb-background: @switch[@labeled][@on][thumb-background];
      --icon: @switch[@labeled][@on][icon-color];
    }
  }

  // -------------------------------------------------------
  // COMPONENT STYLES

  // unthemed variables
  @_calcHeight: @switch[@default][@thumb][height] + (@switch[@default][equalizedPadding] * 2);
  //#DEBUG.value( default-height, @_calcHeight );

  --height: @_calcHeight;
  --padding: @switch[@default][equalizedPadding];
  --border-radius: @switch[@default][border-radius];

  --thumb-font-size: @switch[@default][@thumb][icon-font-size];
  --thumb-width: @switch[@default][@thumb][width];
  --thumb-height: @switch[@default][@thumb][height];
  --thumb-gap: @switch[@default][@thumb][state-position-gap];
  --thumb-border-radius: @switch[@default][@thumb][border-radius];


  display: flex;
  align-items: center;
  //gap: @switch__checkboxInput[label-spacing];

  &__toggle {
    display: block;
    @_checkWidth: 16 * @switch[@default][@thumb][width]*2 + @switch[@default][equalizedPadding]*2 + @switch[@default][@thumb][state-position-gap];
    //#DEBUG.value( default-toggle-width-calc, @_checkWidth );
    --toggleWidth: calc(var(--thumb-width)*2 + var(--padding)*2 + var(--thumb-gap));
    width: var(--toggleWidth);
    height: var(--height);
    padding: var(--padding) 0;
    background: var(--background);
    border: var(--border);
    border-radius: var(--border-radius);
    box-shadow: inset var(--box-shadow);

    &Thumb {
      display: flex;
      align-items: center;
      justify-content: center;

      width: var(--thumb-width);
      height: var(--thumb-height);
      background: var(--thumb-background);
      border: var(--thumb-border);
      border-radius: var(--thumb-border-radius);
      box-shadow: var(--thumb-box-shadow);

      // prime before element for icon use
      &::before {
        &:extend(.iconLike );
        color: var(--icon);
        font-size: var(--icon-size);
      }

      // Thumb Positioning by State
      transform: translateX(var(--thumb-off-position));
      // calc on position using CSS vars to allow customizing switch width
      --thumb-off-position: var(--padding);
      --thumb-on-position: calc(var(--thumb-width) + var(--thumb-gap) + var(--padding));

      // Switch of animation
      & when not  ( @switch[animation-speed] = 0 )
			  and ( isnumber(@switch[animation-speed]) )
      {
        transition: transform @switch[animation-speed] @switch[animation-easing];
      }
    }
  }
  // allow individual glyphs for each state in each variant
  &:not(&--labeled) [data-state=unchecked] &__toggleThumb
  {
    //.useIconOverride( @switch[@default][@off][useIcon], @allowDelete: false );
  }
  &:not(&--labeled) [data-state=checked] &__toggleThumb
  {
    transform: translateX(var(--thumb-on-position));
    //.useIconOverride( @switch[@default][@on][useIcon], @allowDelete: false );
  }

  &__label {
    color: var(--background);
    margin: 0 .5em 0 .25em;
    white-space: nowrap;
    [class*="icon"] {
      margin-right: .25em;
    }
    display: flex;
    [class*="iconOnly"] {
      display:flex; // to allow centering of icon
      align-items: center;
    }
  }
  [data-state="checked"] + .switch__label {
    --background: @switch[@default][@on][background];
  }
  // -------------------------------------------------------
  //-- VARIANT: worded labels

  &--labeled {
    // unthemed variables
    @_calcHeightLabeled: unit(@switch[@labeled][@thumb][height] + (@switch[@labeled][equalizedPadding] * 2), em);
    //#DEBUG.value( labeled-height, @_calcHeightLabeled );

    --height: @_calcHeightLabeled;
    --padding: @switch[@labeled][equalizedPadding];
    --border-radius: @switch[@labeled][border-radius];

    --thumb-font-size: @switch[@labeled][@thumb][icon-font-size];
    --thumb-width: @switch[@labeled][@thumb][width];
    --thumb-height: unit(@switch[@labeled][@thumb][height], em);
    --thumb-gap: unit(@switch[@labeled][@thumb][state-position-gap], em);
    --thumb-border-radius: @switch[@labeled][@thumb][border-radius];

    --label-checked: @switch[@labeled][@on][defaultText];
    --label-unchecked: @switch[@labeled][@off][defaultText];

    [data-label-checked] { --label-checked: attr(data-label-checked); }
    [data-label-unchecked] { --label-unchecked: attr(data-label-unchecked); }
  }

  &--labeled &__toggle {
    position: relative;
    display: grid;
    grid-template-columns: var(--thumb-width) var(--thumb-width);
    grid-gap: 0 var(--thumb-gap);

    &::before,
    &::after {
      color: var(--color);
      text-align: center;
      align-self: center;
    }
    &::before { content: var(--label-checked); }
    &::after { content: var(--label-unchecked); }

    &Thumb {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(var(--thumb-off-position));
    }
  }
  &--labeled [data-state=unchecked] &__toggleThumb
  {
    //.useIconOverride( @switch[@labeled][@off][useIcon], @allowDelete: false );
  }
  &--labeled [data-state=checked] &__toggleThumb
  {
    //.useIconOverride( @switch[@labeled][@on][useIcon], @allowDelete: false );

    transform: translateY(-50%) translateX(var(--thumb-on-position));
  }

  //// -------------------------------------------------------
  //// OPTIONAL CUSTOM STYLES
  //.appendCustomStyles( @switch );
}
