 // main: ../build.less
 @import (reference) '../config';

@viewHeader_height: .em(60)[];
@viewHeader_logo_collapsed_width: 6.5rem;
// @viewHeader_logo_fixed_width: 25.5em; // bit wider than view Menu
@viewHeader_logo_fixed_width: .em(408)[]; // bit wider than view Menu
@viewHeader_logo_fluid_width: .em(200)[];
// @extendedSearch_height: 18rem;
// @extendedSearch_height: 24rem;

@viewMain_max_width: .em(960)[];
@fixed_column_gap: .em(24)[];

// these settings are duplicated in components/viewMenu -> move to config
@viewMenu_max_width: 35rem;
// @fixed_viewMenu_width: calc(25vw - (@fixed_column_gap*2/3));
@fixed_viewMenu_width: .em(400)[];
@viewMenu_collapsed_width: 5rem;



.view {
	overflow-x: hidden;
	// background-color: @color-primary-900;
	background-color: @window[@bg][color];
	background-image: @window[@bg][image];
	background-size: @window[@bg][size];
	background-position: @window[@bg][position];

	&Header {
		height: @viewHeader_height;
		position: relative;
		z-index: @z-toolbar;

		&__toolbar {
			height: @viewHeader_height;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&__masthead {
			// TODO: odd column gap addition needed to match, y?
			// width: @viewMenu_max_width + (@fixed_column_gap * 3.2);
			// width: @viewMenu_max_width;
			// width: @viewHeader_logo_fixed_width;
			flex-shrink: 0;
			@media @desktop {
				width: @viewHeader_logo_fixed_width;
			}

			// collapse Logo to icon if menu is hidden? / not sure, tba
			// .hide\:menu &,
			// .show\:searchFocus & {
			// 	width: @viewHeader_logo_collapsed_width;
			// }
		}
	}

	.extendedSearch {
		display: none;
		visibility: hidden;
	}

	&Menu,
	&Main,
	&Side {
		max-height: 100%;
		overflow-y: auto;
		// overflow-x: visible;
	}

	&Main {
		padding: 2em 1em 0;
	}

	&Side {
		padding: 2em 1em;

		@media @fixed {
			padding: 2em 1em 2em 1em;
		}
	}

	// Responsive View States
	@media @fluid {
		&Body {
			height: calc(100vh - @viewHeader_height );

			display: grid;
			grid-template-columns: 95vw 100vw 100vw;
			grid-template-rows: 2.5rem 1fr;
			grid-template-areas:
				"menu controls controls"
				"menu main side";
			transform: translateX(-95vw);

			// app content as sliding views
			&.show\:main { transform: translateX(-95vw); }
			&.show\:side { transform: translateX(-195vw); }
			&.show\:menu { transform: translateX(0); }
		}

		&Menu { grid-area: menu; }
		&Main { grid-area: main; }
		&Side { grid-area: side; }

		// ensure controls transition when changing from main to side
		&Controls {
			grid-area: controls;
			max-width: 50%;

			.show\:side & {
				transform: translateX(100vw);
			}
		}

		&Header.show\:extendedSearch {
			position: relative;

			.extendedSearch {
				position: fixed;
				display: block;
				visibility: visible;
				z-index: @z-foreground;
				height: calc(100vh - @viewHeader_height);
				overflow-y: auto;
			}
		}
	}

	@media @tablet {
		&Header.show\:extendedSearch {

			.extendedSearch {
				position: fixed;
				right: 0; left: 0;
				display: block;
				visibility: visible;
				z-index: @z-foreground;
				height: calc(100vh - @viewHeader_height);
				overflow-y: auto;
			}
		}
	}

	@media @fixed {
		&Body {
			height: calc(100vh - @viewHeader_height );
			display: grid;
			grid-template-columns: 25em 60vw 40vw;
			grid-template-areas: "menu main side";
			transform: translateX(-25em);

			&.show\:menu { transform: translateX(0); }
		}
		// hide mobile state controls
		&Controls { display: none; visibility: hidden; }

		// optional splashscreen spanning both columns
		&Main--splashScreen { grid-column: 2 / span 2;}

		&Header.show\:extendedSearch {

			.extendedSearch {
				display: grid;
				visibility: visible;
				overflow-y: visible;
				position: fixed;
				z-index: @z-appMenu + 10;
				left: calc( 3em + 1px);
				top: @viewHeader_height - 0.75rem;
				right: 0.5em; 
				height: auto;
				// box-shadow: @search[box-shadow];
				
				// simulating shadow box under search + input
				&::before {
					content: '';
					pointer-events: none !important;
					background-color: transparent !important;
					position: absolute;
					z-index: @z-appMenu;
					top: ((@viewHeader_height - 1.333em) * -1); 
					left: 0; right: 0; bottom: 0;
					border-radius: @header[@searchbox][border-radius];
					box-shadow: @search[box-shadow];
				}
			}
			.viewHeader__searchBox {
				// prevent box shadow from overlaying searchBox
				position: relative;
				z-index: @z-appMenu + 11;
			}
		}

		
	}

	// Desktop View States
	@media @desktop {

		&Body {
			grid-template-columns: @nav[fixed-width] 1fr 28%;
			column-gap: @fixed_column_gap;
			transform: translateX(0);

			// collapsed menu + wide sidebar state
			.hide\:menu & {
				grid-template-columns: @viewMenu_collapsed_width @viewMain_max_width 1fr;
			}
		}

		&.bookmarks .viewBody:not(.has\:viewSide) {
			grid-template-columns: @nav[fixed-width] minmax(@viewMain_max_width, 100%) 1fr;
		}

		// extended search open state
		&Header.show\:extendedSearch {

			.extendedSearch {
				left: @viewHeader_logo_fixed_width;
				top: @viewHeader_height - 0.75rem;
				right: 0.5em; 
				
			}
		}

	}
}