// main: ../index.less
@import (reference) '../config.less';

.tooltip {
	z-index: @z-modal;
	position: absolute;
	padding: @tooltip[@padding][vertical] @tooltip[@padding][horizontal];
	background-color: @tooltip[bg];

	// .toggle-visibility( ~'.is\:open' );

	color: @tooltip[color];
	// pointer-events: none;
	box-shadow: @tooltip[box-shadow];
	border-radius: @tooltip[border-radius];
	// visibility: hidden;

	&__head {
		display: flex;
	}

	&__title {
		#set.text(16, 24);
		font-size: .em(16)[];
		padding: .em(8)[] .em(10)[];
		#set.padding(8, 10);
		#set.margin(bottom, 8);
		font-weight: bold;
		border-bottom: 1px solid @color-primary-400;
		white-space: no-wrap;
	}

	&__data {
		#set.padding(8, 10);
		#set.text(14, 24);

		dt {
			font-weight: bold;
			#set.padding(right, 8);
		}

		dd {
			#set.margin(bottom, 4);
		}
	}

	// &.is\:open {
	// 	visibility: visible;
	// }

	&--translation {
		// max-width: 40rem !important;
		width: 40rem;

		.tooltip__head > * + * {
			margin-left: .25rem;
		}
	}

}

// Hack to prevent the tooltip from ending right at the end of the screen when no other boundary ref was provided
.hint {
	margin-left: -8px;
	padding: @hint[@padding][vertical] @hint[@padding][horizontal];
	max-width: @hint[max-width];
	font-size: @hint[font-size];
}
