#set()
{
	.box-distance ( @A:top, @B:none, @C:none, @D:none, @type:padding )
	{
		& when (iskeyword(@A))
		  and  (isnumber(@B))
		{
			// #set.rem( @{type}-@{A}, @B );
			@{type}-@{A}:unit((@B / @__REM_SCALING), rem);
		}
		& when (isnumber(@A))
		{
			@{type}+_: unit((@A / @__REM_SCALING), rem);

			& when (isnumber(@B))
			{
				@{type}+_: unit((@B / @__REM_SCALING), rem);
			}
			& when (isnumber(@C))
			{
				@{type}+_: unit((@C / @__REM_SCALING), rem);
			}
			& when (isnumber(@D))
			{
				@{type}+_: unit((@D / @__REM_SCALING), rem);
			}
		}
	}


	.padding ( @A:top, @B:none, @C:none, @D:none )
	{
		#set.box-distance( @A, @B, @C, @D, padding );
	}


	.margin ( @A:top, @B:none, @C:none, @D:none )
	{
		#set.box-distance( @A, @B, @C, @D, margin );
	}
}
