

.emojiSelectMenu {
	background-color: @menu[bg];
	border-radius: @menu[border-radius];
	box-shadow: @menu[box-shadow];

	&__trigger {
		.inputLike;
		background-color: @input[bg];
	}
	&__value {
		#set.text(21, 21);
	}
	
	&__chevron {
	}
}

.select--emoji {
	em-emoji-picker {
		--background-rgb: 255, 0, 0;
		// --border-radius: 24px;
		// --category-icon-size: 24px;
		// --color-border-over: rgba(0, 0, 0, 0.1);
		// --color-border: rgba(0, 0, 0, 0.05);
		// --font-family: 'Comic Sans MS', 'Chalkboard SE', cursive;
		// --font-size: 20px;
		// --rgb-accent: 255, 105, 180;
		// --rgb-background: 262, 240, 283;
		// --rgb-color: 102, 51, 153;
		// --rgb-input: 255, 235, 235;
		// --shadow: 5px 5px 15px -8px rebeccapurple;

		// height: 50vh;
		// min-height: 400px;
		// max-height: 800px;
	}
}