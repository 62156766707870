// main: ../build.less
@import (reference) '../config';

@topicLabel-border-radius: 5;

.topicLabels {
	display: flex;
	flex-flow: row wrap;
	width: 100%;
}

.topicLabel {
	#set.text(14, 14);
	margin-top: @topic[spacing];
	margin-right: @topic[spacing];
	border-radius: @topic[border-radius];
	display: inline-flex;
	flex-flow: row nowrap;
	max-width: 100%;
	cursor: pointer;

	transition: @transition[@controls][smooth];

	>* {
		color: @topic[color];
		text-decoration: none;
		display: flex;
		flex-flow: row nowrap;
		max-width: 100%;

		.topicLabel__rank {
			flex-shrink: 0;
		}

		.topicLabel__label {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__label,
	&__count,
	&__rank {
		display: inline-flex;
		flex-flow: row nowrap;
		align-items: center;
		white-space: nowrap;
		height: @topic[height];
	}

	&__label {
		padding-left: 0.5em;
		padding-right: 0.75em;
		text-transform: capitalize;
	}

	&__count,
	&__rank {
		font-weight: bold;
		padding-left: 0.75em;
		padding-right: 0.5em;
	}
	&__count {
		border-top-right-radius: @topic[border-radius];
		border-bottom-right-radius: @topic[border-radius];
	}
	&__rank {
		border-top-left-radius: @topic[border-radius];
		border-bottom-left-radius: @topic[border-radius];
	}

	background-color: @topic[bg];

	&:hover {
		background-color: @topic[@marker][bg];
	}

	&__count,
	&__rank {
		background-color: @topic[@marker][bg];
		color: @topic[@marker][color];
	}

	// Color Variations
	&.sentiment\:positive { background-color: @color-positive-200;
		&:hover { background-color: @color-positive-300; }
	}
	&.sentiment\:positive &__count {
		color: @color-positive-text;
		background-color: @color-positive-300;
	}

	&.sentiment\:neutral { background-color: @color-neutral-200;
		&:hover { background-color: @color-neutral-300; }
	}
	&.sentiment\:neutral &__count {
		color: @color-neutral-text;
		background-color: @color-neutral-300;
	}

	&.sentiment\:negative { background-color: @color-negative-200;
		&:hover { background-color: @color-negative-300; }
	}
	&.sentiment\:negative &__count {
		color: @color-negative-text;
		background-color: @color-negative-300;
	}
}
