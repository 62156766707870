
.pageFooter {
	display: flex;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.5);
	width: 100%;
	#set.text(12, 24);
	#set.padding(16, 24);

	&__content {
		width: 100%;
		max-width: 100vh;
		display: flex;
		justify-content: space-between;
	}

	&__logo {
		display: flex;
		justify-content: center;
		svg {
			#set.rem(height, 24);
		}
	}

	&__copy {
		display: flex;
		justify-content: center;
	}

	&__links {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
	}

	&__link {
		#set.margin(0, 8);
	}

	@media @mobile {

		&__content{
			flex-flow: column;
		}

		&__content > * {
			#set.margin(top, 4);
		}

	}

	@media @tablet {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(255, 255, 255, 0.9);
	}
}