// main: ../build.less
@import (reference) '../config';

.newTopicsWidgets
{
	background: none !important;
	box-shadow: none !important;
	padding-bottom: .em(48)[];

	&__toolbarActions {
		padding: .em(6)[] 0;
		display: flex;
		gap: 0.25em;
		justify-content: flex-end;
	}
}

.newTopicsWidget, .widget--topics {

	.tooltip {
		&__data {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}
	}

	table {
		font-size: .em(14)[];
		thead {

		}
	}
	.dataTable {
		td {  }

		&__head {
			font-weight: bold;
			//white-space: nowrap;
			border-bottom: 1px solid @color-black-200;
			td { padding: 0.333em 0.666em; }
		}
		&__body {
			tr:first-child td {
				padding-top: 0.75em;
			}
			tr:nth-child(even) {
				background-color: @color-black-50;
			}
		}
		&__value {
			padding: 0.333em 0.666em;
			&--numeric {
				text-align: right;
				padding: 0.333em 1em 0.333em 0.75em;
			}
		}
	}
}
