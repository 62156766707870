
.popoverTrigger {

}

.popoverContent {
	// position: fixed;
	// z-index: @z-modal;
	// position: relative;
	// max-height: var(--radix-popover-content-available-height);
	max-width: var(--radix-popover-content-available-width);
	// max-height: var(--radix-popover-content-available-height);
	overflow: auto;
	// max-width: var(--radix-popover-content-available-width);
	// width: var(--radix-popover-trigger-width);
}
.popoverContent.hint {
	z-index: @z-modal;
	padding: @tooltip[@padding][vertical] @tooltip[@padding][horizontal];
	background-color: @tooltip[bg];
	color: @tooltip[color];
	box-shadow: @tooltip[box-shadow];
	border-radius: @tooltip[border-radius];

	//width: var(--radix-popover-content-available-width);
	//width: 800px;
	width: var(--radix-popover-content-available-width);
	max-width: 40rem;
	//max-width: 40rem;
	min-width: 25rem;
}


.hintTrigger {
	padding: 0;
	color: @color-primary;
	background-color: transparent;
	margin-left: .3em;
}
