@import (reference) "../config.less";

.customStreamForm {

	&__row {
		display: flex;
		gap: .em(16) [];
		flex-flow: row wrap;
		#set.margin(bottom, 8);
		#set.margin(top, 8);
	}

	&__section {
		width: 100%;
	}

	&__sectionTitle {
		display: flex;
		margin-top: 1.5rem;
		margin-bottom: 1.25rem;
		flex-flow: row wrap;
		h3 {
			font-size: .em(18) [];
			font-weight: 700;
		}
	}

	&__switches {
		display: flex;
		margin-left: auto;
		font-size: .em(16) [];
		.switch {
			--height: .em(24)[];
			--thumb-width: 1em;
			--thumb-height: 1em;
		}
	}

	.field {
		flex: 0.5;
	}
	.field__label {
		padding-top: 0;
	}
	.field--emoji {
		flex: 0 0 .em(64) [];
	}
	.field--text {
		flex: auto;
	}
	.field--text .button {
		border: 0;
		&:focus, &:focus-visible {
			outline: none;
			background-color: @color-primary-100;
		}
	}

	.searchTokens {
		margin-top: .em(16) [];
	}
	.searchTokens__heading {
		.font--label;
	}
	.searchTokens__section {
		margin-top: .em(8) [];
	}
}

.customStreamBtn {
	width: .em(200) [];
	left: 50%;
	transform: translateX(-50%);
	position: absolute;
	bottom: 2rem;
	z-index: 9999;
	box-shadow: @menu[box-shadow];
	border: 1px solid @color-black-100;

	.icon {
		color: @color-signal;
	}
	&:hover .icon {
		color: unset;
	}

	// need to use :active selector to not have transform overwritten by .button:active transform
	// requires !important because of button-v2.less .button:disabled:hover transform reset
	&:active, &:hover, &.is\:disabled:active, &.is\:disabled:hover {
		transform: translate3d(-50%, @buttons[simulate-press], 0)!important;
		//background-color: #fff;
	}
	&.is\:disabled:hover {
		background-color: #fff;
	}

	//@media @tablet {
	//	z-index: auto;
	//	box-shadow: none;
	//	transform: none;
	//	left: auto;
	//	bottom: auto;
	//	right: 0;
	//	top: 2.5rem;
	//}

	&.is\:disabled {
		cursor: not-allowed;
		pointer-events: auto;
		.icon {
			color: unset;
		}
	}
}

.customStreamModal {
	max-width: 40em;
	display: flex;
	flex-flow: column;
	overflow: auto;

	.dialog__title {
		#set.text(24, 26);
	}

	.dialog__content {
		margin-top: .em(4) [];
	}

	.dialog__close {
		display: block;
		background-color: fade(@color-text-faded, 20%);
		color: @color-primary-faded;
		height: 3rem;
		width: 3rem;
		position: absolute;
		top: .em(-8) [];
		right: .em(-8) [];
		border-radius: 50%;
		transition: all 0.3s;
		font-weight: 900;

		&:focus {
			outline: none;
			background-color: fade(@color-text-faded, 40%);
		}

		&:focus-visible {
			outline: revert;
			background-color: fade(@color-text-faded, 40%);
		}

		&::before {
			font-size: 1.5em;
			font-weight: 900;
		}

		&:hover {
			color: @color-primary;
			background-color: fade(@color-text-faded, 40%);
		}
	}
	&__stickyHeader {
		position: sticky;
		top: 0;
		background-color: @color-bg;
		z-index: 1;
	}


	&__submit {
		#set.margin(top, 12);
	}

	.dialog__error {
		color: @color-text-error;
	}

	@media @tablet {
		min-height: 70vh;

		height: auto;
	}

	@media @desktop {
		padding: .em(42) [] .em(48) [] .em(36) [] .em(48) [];
		min-height: 56rem;

		.dialog__close {
			top: .em(-20) [];
			right: .em(-30) [];
		}
	}
}
