// main: ../../index.less
@import (reference) "../../config.less";

// COMPONENT DEFAULTS:
// --------------------------------------------------------------------------
// Sample Default config:

// @buttons: {
// 	@selectors: {
// 		base: '.button';
// 		icon-only: ~'.has\:iconOnly';
// 		disabled: ~'.is\:disabled';
// 		active: ~'.is\:active';
// 	}
// 	font-family: @__FONT_NAME;
// 	spacing: .em(4)[];
// 	simulate-press: 1;

// 	@size: {
// 		@default: {
// 			selector:'';
// 			font-size: .em(16)[];
// 			icon-size: .em(16)[];
// 			height: .em(26)[];
// 			border-radius: .em(5)[];
// 			@padding: {
// 				horizontal: .em(24)[];
// 				vertical: .em(8)[];
// 			}
// 		}
// 	}

// 	@variant: {	
// 		@default: {
// 			selector: '';	
// 			border-width: 1px;
// 			border-radius: .em(3)[];
// 			bg: #333;
// 			color: #fff;
// 			icon: #aaa;
// 			border: #333;
// 			@hover: {
// 				bg: #444;
// 				color: #444;
// 				icon: #666;
// 				border: #333;
// 			}
// 			@active: {
// 				bg: #444;
// 				color: #444;
// 				icon: #666;
// 				border: #333;
// 			}
// 		}
// 	}
// }


// @button-simulate-press: 1;

// GLOBAL STYLES:
// --------------------------------------------------------------------------
// @buttonSelector: @buttons[selector];
// @iconOnlySelector: @buttons[icon-only-selector];
@buttonSelector: @buttons[@selectors][base];
@iconOnlySelector: @buttons[@selectors][icon-only];
@disabledSelecor: @buttons[@selectors][disabled];
@activeSelector: @buttons[@selectors][active];
button { 
	font-family: @buttons[font-family];
	border: none; 
	cursor: pointer;

	display: inline-flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
}

@{buttonSelector} 
{
	&:extend( .buttonLike all );
}


.buttonLike 
{
	display: inline-flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;

	font-family: @buttons[font-family];
	white-space: nowrap;
	cursor: pointer;

	backface-visibility: hidden;
	transform: translate3d(0, 0, 0);
	transition: 
		transform .1s ease-out,
		color .15s ease-out,
		background .25s ease-out,
		// outline-offset .25s ease-out, // TODO could be cool, but cant get it to work https://codepen.io/hgrimelid/pen/PwLrWe
		border-color .25s ease-out;
	// TODO better integrate transition options

	// this setting will likely stay the same on all variants but is overrideable in the mixins
	border-radius: @buttons[@size][@default][border-radius];

	&::before {
		transition: transform .1s ease-out,
			color .15s ease-out;
	}

	a& { text-decoration: none; }

	// button& { }

	// &:hover  { }

	&:focus  { outline: 0; }
	
	&:active {
		& when ( @buttons[simulate-press] > 0 ) {
			transform:  translate3d(0, @buttons[simulate-press], 0);
		}
	} 

	&:not( @{iconOnlySelector} )::before { 
		margin-right: 0.5em; 
		margin-left: -0.25em;
	}

	&@{iconOnlySelector} {
		padding: 0 !important; // as to not need to overwrite paddings in every #button.size
		&::before { margin: 0; }
	}

	& + & { 
		margin-left: @buttons[spacing];
	}

	&@{disabledSelecor} {
		&:hover,
		&:active {
			transform: none !important;
		}
	}
}


// VARIANT CONSTRUCTORS:
// --------------------------------------------------------------------------

#button-v2() 
{		
	.size ( @config ) {
		
		@__selectorExtension: @config[selector];
		@__variantSelector: ~"@{buttonSelector}@{__selectorExtension}";
		
		@{__variantSelector}
		{
			& when (isnumber( @config[font-size] )) {
				font-size: @config[font-size];
			}

			& when (isnumber( @config[height] )) {
				height: @config[height];
				&@{iconOnlySelector} { 
					width: @config[height];
				}
			}

			& when (isnumber( @config[icon-size] )) {
				&::before { font-size: @config[icon-size]; }
			}

			& when (isnumber( @config[@padding][horizontal])) { 
				padding-left: @config[@padding][horizontal];
				padding-right: @config[@padding][horizontal];
			}

			& when (isnumber( @config[border-radius] )) { 
				border-radius: @config[border-radius];
			}

			&@{iconOnlySelector} {
				padding: 0 !important; 
				&::before { margin: 0 !important; }
			}
		}
	}
	.variant( @config: {
		selector: "";
		border-width: 0;
		border-radius: 0;
		
		bg: false;
		color: false;
		icon: false;
		border: 0;
		
		@hover: {
			bg: false;
			color: false;
			icon: false;
			border: false;
		}
		@active: {
			bg: false;
			color: false;
			icon: false;
			border: false;
		}
	}) {

		@__selectorExtension: @config[selector];
		@__variantSelector: ~"@{buttonSelector}@{__selectorExtension}";

		@{__variantSelector}
		{
			& when ( iscolor( @config[bg] )) {
				background: @config[bg];
			}
			& when ( iscolor( @config[color] )) {
				color: @config[color];
			}
			& when ( iscolor( @config[icon] )) {
				&::before { color: @config[icon]; }
			}
			// & when ( @config[border-width] > 0 ), (iscolor(@config[border])) {
			& when ( @config[border-width] > 0 ) {
				border: @config[border-width] solid @config[border];
			}

			& when ( isnumber( @config[border-radius] )) { 
				border-radius: @config[border-radius];
			}

			// &:focus, // is this a good shortcut to maintain focus state?
			&:hover
			{
				& when ( iscolor( @config[@hover][bg] )) {
					background: @config[@hover][bg];
				}
				& when ( iscolor( @config[@hover][color] )) {
					color: @config[@hover][color];
				}
				// & when ( @config[border-width] > 0 ), (iscolor(@config[@hover][border]))  
				& when ( @config[border-width] > 0 ) 
				{
					border-color: @config[@hover][border];
				}
				& when ( iscolor( @config[@hover][icon] )) {
					&::before { color: @config[@hover][icon]; }
				}
			}

			&:active,
			&.is\:active 
			{
				& when ( iscolor( @config[@active][bg] )) {
					background: @config[@active][bg];
				}
				& when ( iscolor( @config[@active][color] )) {
					color: @config[@active][color];
				}
				// & when ( @config[border-width] > 0 ), (iscolor(@config[@active][border])) {
				& when ( @config[border-width] > 0 ) {
					border-color: @config[@active][border];
				}
				& when ( iscolor( @config[@active][icon] )) {
					&::before { color: @config[@active][icon]; }
				}
			}
		}
	}
}