// main: ../build.less
@import (reference) '../config';

// @widget[border-radius]: 5;
// @widget[border-radius]: 20;
// @widget-padding-horizontal: 1.75em;
// @widget-padding-top: 1em;
// @widget-padding-bottom: 0.333em;
// @widget-box-shadow: 0 0 0.5em rgba( 0,0,0,.2);

.widget {
	background-color: @widget[bg];
	box-shadow: @widget[box-shadow];
	border-radius: @widget[border-radius];

	& + & { margin-top: @widget[spacing]; }

	&__header {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		padding: @widget[@padding][top] @widget[@padding][horizontal] 0;
		background-color: @widget[bg];
		border-top-right-radius: @widget[border-radius];
		border-top-left-radius: @widget[border-radius];

		@media @tablet {
			flex-flow: row nowrap;
		}
	}
	&__body {
		display: flex;
		flex-flow: column;
		position: relative;
		//overflow: hidden;
		padding: 0.75em @widget[@padding][horizontal] @widget[@padding][bottom];
		background-color: @color-white;
		border-bottom-right-radius: @widget[border-radius];
		border-bottom-left-radius: @widget[border-radius];
	}
	&__spinner {
		align-self: center;
	}
	&__notice {
		margin: auto;
		#set.padding(16, 0);
		.font--medium;
	}
	&__title{
		#set.text(16, 24);
		font-weight: bold;
		white-space: nowrap;

		display: flex;
		align-items: center;


		// abbr {
		// 	position: relative;
		// 	top: -0.33em;
		// 	left: 0.33em;
		// 	color: @color-black-700;
		// 	#set.text(12, 16);
		// }
	}
	&__subtitle {
		#set.text( 12, 18 );
		color: @color-primary-500;
		font-weight: bold;
		white-space: nowrap;
		display: flex;
		max-width: 20rem;
	}

	&__subtitleClamped {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}


	.widget__controls {
		display: flex;
		justify-content: center;
		align-items: center;

		.select {
			max-width: 10em;
		}
	}

	&__vis {
		// max-height: 42rem;
		display: flex;
		width: 100%;
		position: relative;
		margin: auto;

		&--pack {
			// #set.rem(height, 512);
			#set.rem(height, 360);

			// .pack__label {
			// 	//text-transform: capitalize
			// }
			circle {
				cursor: pointer;
			}
		}
	}

	// Tabbed widget variant
	// Naming of proper tab components diverge from widget namespace
	// for easier sharing with future standalone tab component
	&--tabbed {
		padding: 0;
		background-color: transparent;
	}

	.tabs {
		&__controls {
			display: flex;
		}
		&__control {
			#set.text( 14, 20);
			padding: 0.75em 1em;
			margin-right: 0.1em;
			font-weight: bold;
			color: @color-primary;
			background-color: mix( @color-primary-200, @color-primary-100, 50%);
			transition: background-color .3s ease-out;
			cursor: pointer;

			&.is\:active {
				color: @color-text;
				background-color: @color-white;
				cursor: default;
			}

			&:not(.is\:active):hover {
				background-color: @color-primary-200;
			}

			ild { border-top-left-radius: @widget[border-radius]; }
			&:last-child  { border-top-right-radius: @widget[border-radius];}
		}
	}

	&.has\:error &__body {
		// background-color: red;
		justify-content: center;
	}
	&.has\:error &__vis {
		display: flex;
		justify-content: center;
	}

	&.is\:loading &__body {
		justify-content: center;
	}
	&.is\:loading &__vis {
		display: flex;
		justify-content: center;
	}

	&.is\:loading &__vis > *:not(&__spinner)
	 {
		display: none;
	}

	// Sentiment + other Stats for widget header
	.statBoard {
		display: flex;
		align-items: stretch;
		justify-content: center;

		position: relative;
		z-index: 10;
		margin: .em(10)[] auto .em(-24)[];

		@media @tablet { margin: 0; 	}

		> * {
			#set.text( 11, 16 );
			text-align: center;
			padding: 0.25em 0.5em;
			&:not(:first-child) { border-left: 1px solid @color-black-100; }
		}

		&__item {
			&Label {}
			&Count {
				#set.text( 18, 27 );
				font-weight: 500;
				color: @color-primary-500;
			}
		}

		&__figure {
			min-width: .em(68)[];
			cursor:pointer;
			transition: @transition[@controls][subtle];

			&::after {
				font-family: @__ICON_FONT_NAME;
				display: block;
				#em.text( 24, 24);
			}

			&.sentiment\:none {
				color: @color-black-600;
				&::after { content: @icon-block; }
			}
			&.sentiment\:positive {
				color: @color-positive-500;
				&::after { content: @icon-sentiment_very_satisfied; }
				&:hover {
					background-color: @color-positive-100;
					color: @color-positive-600;
				}
				&.is\:active {
					background-color: @color-positive-500;
					color: @widget[bg];
				}
			}
			&.sentiment\:neutral {
				color: darken(@color-neutral-500, 20%);
				&::after { content: @icon-sentiment_neutral; }
				&:hover {
					background-color: @color-neutral-100;
					color: @color-neutral-600;
				}
				&.is\:active {
					background-color: @color-neutral-500;
					color: @widget[bg];
				}
			}
			&.sentiment\:negative {
				color: @color-negative-500;
				&::after { content: @icon-sentiment_very_dissatisfied; }
				&:hover {
					background-color: @color-negative-100;
					color: @color-negative-600;
				}
				&.is\:active {
					background-color: @color-negative-500;
					color: @widget[bg];
				}
			}
		}
	}

	// Sentiment Range for widget header
	.statRange {
		display: flex;
		align-items: center;

		&__figure {
			display: flex;
			align-items: center;
			cursor: pointer;
			#set.padding(6, 4);

			> span {
				padding: 0 0.5em;
				#set.text( 14, 20 );
			}

			&::before {
				font-family: @__ICON_FONT_NAME;
				#set.text( 24, 24);
			}

			&.sentiment\:positive {
				color: @color-positive-500;
				flex-flow: row-reverse;
				&::before {
					content: @icon-sentiment_very_satisfied;
				}
				&:hover {
					background-color: @color-positive-100;
					color: @color-positive-600;
				}
				//&.is\:active {
				//	background-color: @color-positive-500;
				//	color: @widget[bg];
				//}
			}
			&.sentiment\:neutral {
				//color: @color-neutral-500;
				color: darken(@color-neutral-500, 20%);
				&::before { content: @icon-sentiment_neutral; }
				&:hover {
					background-color: @color-neutral-100;
					color: @color-neutral-600;
				}
				//&.is\:active {
				//	background-color: @color-neutral-500;
				//	color: @widget[bg];
				//}
			}
			&.sentiment\:negative {
				color: @color-negative-500;
				span { width: 2.75em;}
				&::before { content: @icon-sentiment_very_dissatisfied; }
				&:hover {
					background-color: @color-negative-100;
					color: @color-negative-600;
				}
				//&.is\:active {
				//	background-color: @color-negative-500;
				//	color: @widget[bg];
				//}
			}
		}
	}
}

.widget--pieChart {
	//color: green;
	position: relative;

	.widget__body {
		padding: 0;
	}

	.widget__vis {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-evenly;

		.pieChart {
				flex-basis: .em(140)[];
			> svg {
				width: 100% !important;
				height: 100% !important;
				max-width: 100% !important;
			}

			&__labelCols {
				gap: .em(16)[];
				display: flex;
				flex: 1 0 auto;
				flex-flow: row wrap;
				justify-content: space-evenly;
				max-width: 100%;
			}
		}
		padding: 0.75em 1.333em 1.333em;
		position: static;

		.pieChart__labels {
			padding: .5em 0;
			display: flex;
			flex-flow: column;
			justify-content: space-around;
			#set.text(16, 28);
		}

		.pieChart__labelBullet {
			display: inline-block;
			margin-right: .5rem;
			height: 10px;
			width: 10px;
			border-radius: 50%;
		}
		.pieChart__vis {
			position: absolute;
			right: 0.66em;
			bottom: 0.66em;
		}
		.pieChart__labels, .pieChart__vis {
			flex-grow: 1;
		}

		.pieChart__label {
			font-size: .em(14)[];
			line-height: 1.5;
			padding: .em(4)[] 0;
			white-space: nowrap;
			&.is\:focus {
				cursor: pointer;
				text-decoration: underline;
			}
		}
	}

	// &.widget--credibilityDistribution {
	// 	min-height: 10em;
	// }

}

.widget--languageDistribution {
	.pieChart__labels {
		#set.text(14, 26);
	}
	.widget__vis {
		#set.rem(min-height, 160);
	}
}

.widget--credibilityDistribution {

}

.widget--cryptoForecast {
	.widget__body {
		flex-flow: row wrap;
	}
}

.widget--opinionBarStack {
	.widget__vis {
		// #set.rem(height, 256);
		#set.rem(height, 220);
	}
}

.widget--opinionLine {
	.widget__vis {
		// #set.rem(height, 256);
		#set.rem(height, 220);
	}
}

.widget--cryptoLine {
	.widget__vis {
		#set.rem(height, 220);
	}
}

.widget--countryDistribution {

	.widget__body {
		// #set.rem(height, 256);
		// #set.rem(height, 310);
		padding: 0.75em 0 0;
	}

	.widget__vis {
		#set.rem(height, 300);
	}
	.widget__mapControls {
		position: absolute;
		bottom: .em(12)[];
		right: .em(18)[];
		background-color: rgba(255, 255, 255, 0.9);
		#set.padding(2, 0, 3, 0);
		border-radius: 0.5em;

		button {
			background-color: transparent !important;
			color: @color[primary-400];
			&:hover {
				color: @color[primary-500];
			}
		}
	}
}

.betaLabel {
	//position: relative;
	//top: -0.33em;
	//left: 0.33em;
	margin-top: -0.66em;
	margin-left: 0.33em;
	color: @color-black-700;
	#set.text(12, 16);
}

.worldMap {
	border-bottom-right-radius: @widget[border-radius];
	border-bottom-left-radius: @widget[border-radius];
	&.is\:dragging {
		cursor: move;
	}
}

.widget--topics {
	.widget__body {
		#set.rem(min-height, 215); // 5 rows
	}
	.select {
		//min-width: 10rem;
	}
	.buttonGroup {
		font-size: 1.1rem;
	}
	.select--naked .select__value {
		padding-right: 0;
	}
	.icon {
		margin-right: .3em;
	}
	.button {
		border-radius: 0.45em;

		background-color: @header[@button][bg];
		color: @header[@button][color];

		&.is\:active {
			background-color: @menu[@item][@hover][bg];
			color: @menu[@item][@hover][color];
			&::before,
			b {
				color: @color-white;
			}
		}

		&:not(.is\:active):hover {
			background-color: @header[@button][@hover][bg];
			color:  @header[@button][@hover][color];
			&::before { color:  @header[@button][@hover][color]; }
		}
	}
	.showMore {
		display: flex;
		flex-flow: column nowrap;
		line-height: 1;
		margin-top: .75em;
		color: @color[primary-400];
		.icon, .icon:before {
			//display: inline-block;
			line-height: .75;
		}
	}
}
