// main: ../index.less
@import (reference) '../config.less';

// TODO firefox compat https://jsfiddle.net/ldetomi/4Lt2ro5z/9/

// scrollbarStyles
.styledScrollbar 
{
	scrollbar-width: thin;
	scrollbar-color: @scrollbar[color] transparent;

	&::-webkit-scrollbar { 
		width: @scrollbar[width] + (@scrollbar[inset] * 2); 
		
		&-track { background-color: inherit; }
		&-track-piece { background-color: transparent; }
		&-thumb {
			background-color: @scrollbar[color];
			background-clip: padding-box;
			border: @scrollbar[inset] solid transparent;
			border-radius: @scrollbar[border-radius] + @scrollbar[inset];
			min-height: .em(100)[];
		}
	}
}

.noScroll {
	overflow: hidden;
	// padding-right: 15px;
}

.noScrollbar {
	scrollbar-width: thin;
	scrollbar-color: transparent transparent;

	&::-webkit-scrollbar {
		width: 1px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
	}
}

.noScrollbar::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}

// .styledScrollbar--inverted {
// 	&::-webkit-scrollbar {
// 		#set.rem( width, 14 );
// 	}
// 	&::-webkit-scrollbar-track {
// 		background-color: @color-primary-100;
// 	}
// 	&::-webkit-scrollbar-thumb {
// 		background-color: @color-primary-500;
// 		border: 0.3rem solid @color-primary-100;
// 		#set.rem( border-radius, 5 );
// 		min-height: 4rem;
// 	}
// }

