// out: test/icons.css
// main: ../build.less
@import (reference) "../config";

// Register @font-face
// (this could be moved to _shared, but declaration can differ per
// app as long as iconfont generation is not integrated into build step)
@font-face {
	font-family: '@{__ICON_FONT_NAME}';
	src: url('@{__ICON_FONT_PATH}/@{__ICON_FONT_NAME}.ttf') format('truetype'),
	url('@{__ICON_FONT_PATH}/@{__ICON_FONT_NAME}.woff') format('woff'),
	url('@{__ICON_FONT_PATH}/@{__ICON_FONT_NAME}.svg') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}


// Import generic Icons declarations with the following config
@import (once) "../_shared/icons";

@iconNamespace: icon; // classname base
@iconDelimiter: ~":"; // bem-style delimiter (character used to separate {icon} & {name} in plain text)
@iconDelimiterSanitized: ~"\:"; // sanitized delimiter for usage as class (example: escaping ":")

@iconEnableParticles: 1; // enable usage of <i>glyphName</i> with css ligatures
@iconParticleSelector: i; // css selector to be used for defining particles

@icon-size-default: 18; // default size for icons

// Print actual icon classes
@import (reference) "../_shared/lib/icons";

#icon.registerList(@icon-names-list,
	@iconNamespace,
	@iconDelimiterSanitized );

// Available Icons
@icon-names-list:
	add,
	add_box,
	sort_by_alpha,
	new,
	file_download,
	settings,
	add_circle,
	add_circle_outline,
	block,
	clear,
	content_paste,
	dashboard,
	home,
	delete,
	delete_outline,
	content_copy,
	save,
	sort,
	file_copy,
	storage,
	format_align_left,
	format_list_bulleted,
	insert_chart,
	calendar,
	done,
	globe,
	globe_alt,
	visibility_on,
	style,
	tune,
	sun,
	moon,
	apps,
	arrow_drop_down,
	arrow_drop_up,
	arrow_forward,
	arrow_back,
	arrow_upward,
	arrow_downward,
	menu,
	vert_dots,
	unfold_less,
	unfold_more,
	person,
	grid,
	sentiment_neutral,
	sentiment_very_dissatisfied,
	sentiment_very_satisfied,
	star,
	star_outline,
	bug_report,
	bookmarks,
	bookmark,
	bookmark_outline,
	check_circle,
	remove_circle,
	exit,
	table,
	translate,
	globe,
	globe_alt,
	public,
	lock_open,
	lock_outline,
	search,
	view_agenda,
	view_headline,
	logout,
	saved_search,
	filter,
	search_off,
	visibility_off,
	text_snippet,
	picture_as_pdf,
	bubbles,
	bubble_chart,
	book,
	leaderboard,
	magic,
	wand,
	insights,
	report,
	suspect,
	trusted,
	edit,
	construction,
	build,
	reload,
	magic1,
	file_pdf,
	refresh,
	rss_feed,
	update_daterange,
	facebook,
	facebook_box,
	twitter,
	telegram,
	trending_up,
	location,
	cart,
	business,
	bullseye,
	info_outline,
	share,
	mail_outline,
	whatsapp,
	link,
	launch,
	chevron_back,
	newspaper;

// ICON VARIABLES

@import 'icomoon/variables';

.icon\:chevron_down {
	transform: rotate(-90deg);
	&:before {
		content: @icon-chevron_back;
	}
}
.icon\:chevron_up {
	transform: rotate(90deg);
	&:before {
		content: @icon-chevron_back;
	}
}
