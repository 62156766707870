// main: ../build.less

@inter-fontname: "v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese";

@font-face {
	font-family: 'Inter';
	src:
		url('@{__FONT_PATH}/inter-@{inter-fontname}-regular.woff2') format('woff2'),
		url('@{__FONT_PATH}/inter-@{inter-fontname}-regular.woff') format('woff'),
		url('@{__FONT_PATH}/inter-@{inter-fontname}-regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	// font-display: block;
}

@font-face {
	font-family: 'Inter';
	src:
		url('@{__FONT_PATH}/inter-@{inter-fontname}-500.woff2') format('woff2'),
		url('@{__FONT_PATH}/inter-@{inter-fontname}-500.woff') format('woff'),
		url('@{__FONT_PATH}/inter-@{inter-fontname}-500.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	// font-display: block;
}

@font-face {
	font-family: 'Inter';
	src:
		url('@{__FONT_PATH}/inter-@{inter-fontname}-600.woff2') format('woff2'),
		url('@{__FONT_PATH}/inter-@{inter-fontname}-600.woff') format('woff'),
		url('@{__FONT_PATH}/inter-@{inter-fontname}-600.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	// font-display: block;
}

@font-face {
	font-family: 'Inter';
	src:
		url('@{__FONT_PATH}/inter-@{inter-fontname}-700.woff2') format('woff2'),
		url('@{__FONT_PATH}/inter-@{inter-fontname}-700.woff') format('woff'),
		url('@{__FONT_PATH}/inter-@{inter-fontname}-700.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	// font-display: block;
}

@font-face {
	font-family: 'Inter';
	src:
		url('@{__FONT_PATH}/inter-@{inter-fontname}-800.woff2') format('woff2'),
		url('@{__FONT_PATH}/inter-@{inter-fontname}-800.woff') format('woff'),
		url('@{__FONT_PATH}/inter-@{inter-fontname}-800.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
	// font-display: block;
}
